const config = {
  aws: {
    aws_project_region: 'us-west-1',
    aws_cognito_identity_pool_id:
      'us-west-1:19913c7b-755e-4106-839a-7e66ad7d1493',
    aws_cognito_region: 'us-west-1',
    aws_user_pools_id: 'us-west-1_gXj0QZAwZ',
    aws_user_pools_web_client_id: '4r7svkkt8u2gl2pvdqtvpse0vk',
    aws_appsync_region: 'us-west-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_user_files_s3_bucket: 'aicdatastore170549-prod',
    aws_user_files_s3_bucket_region: 'us-west-1',
    endpoints: [
      {
        name: 'prod',
        endpoint:
          'https://vkrunsfz7zferbkc545birwv5e.appsync-api.us-west-1.amazonaws.com/graphql'
      }
    ],
    oauth: {},
    authenticationFlowType: 'USER_SRP_AUTH'
  }
};

export default config;
