// export const sendFax = /* GraphQL */ `
//   query SendFax($input: FaxInput!) {
//     sendFax(input: $input)
//   }
// `;

export const generatePatientId = /* GraphQL */ `
  query GeneratePatientId {
    generatePatientId
  }
`;

// agentId: String
// roles: [Role]
// aclRules: [ACLRule]
export const getAgent = /* GraphQL */ `
  query GetAgent($agentId: ID!) {
    getAgent(agentId: $agentId) {
      locationName
      locationId
      timeZone
      state
      agentId
      roles
      consolidatedACLRule
      aclRules {
        rule
        role
      }
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers($nextToken: String) {
    listUsers(nextToken: $nextToken) {
      # listUsers {
      items {
        userId
        lastName
        firstName
        defaultRole
        role
        aclRule
        queueDepth
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listAgents = /* GraphQL */ `
  query ListAgents(
    $agentId: ID
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAgents(
      agentId: $agentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        agentId
        queueDepth
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPatientBucket = /* GraphQL */ `
  query GetPatientBucket($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      bulletinBoard
      agentId
      patientId
      patientFirstName
      patientLastName
      patientStatus
      patientStatusEffectivePeriod {
        endDate
        startDate
      }
      patientStatusHistory {
        effectivePeriod {
          startDate
          endDate
          recurring
        }
        notes
        afterStatus
        agentId
        beforeStatus
        date
      }
      dob
      homePhoneNumber

      patientProfile {
        patientInfo {
          preferredContactMethod
          address {
            city
            state
            streetName
            zip
          }
          secondaryAddress {
            city
            county
            state
            streetName
            zip
          }
          email
          gender
          patientWeightLB
          cellphoneNumber
          preferredLanguage
          bestContact
          alternateContact {
            firstName
            lastName
            relationship
            phone
          }
          toContactPatient
          toLeaveMessage
          bestTimeToContact
          ssn
          hipaaContact
          preference {
            surveys {
              preferred
              updatedAt
            }
          }
        }

        insuranceInfo {
          isPatientInsured
          primary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
          secondary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
          tertiary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            groupId
            insuranceExpireDate
            lastName
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
          }
        }

        allergyInfo {
          allergen
          entered
          reaction
          recordNumber
        }

        medicationInfo {
          discontinuationDate
          dosage
          frequency
          medication
          recordNumber
          startDate
        }

        weightInfo {
          changeFromLastKnown
          entered
          lastKnown
          recordNumber
          unitOfWeight
          weight
          weightLB
        }
      }

      hcpProfile {
        items {
          prescriberId
          patientId
          prescriber {
            NPINumber
            prescriberLastName
            prescriberFirstName
            taxIDNumber
            updatedAt
            officePhoneNumber
            officeFaxNumber
            needTreatmentRecords
            dontSendSystemGeneratedFaxes
            officeAddresses {
              city
              state
              streetName
              county
              zip
              id
              phoneNumber
              faxNumber
              default
              officeContactName
              addressTitle
            }
          }
        }
      }

      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }

      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }

      notes {
        date
        type
        note
        createdBy
      }

      updatedAt

      referral {
        archivedDrugReferrals {
          agentId
          archivedDate
          orderName

          archivedReferral {
            originalReceivedDate
            therapyId
            referralId
            noOfTreatments
            prescriberId
            referralType
            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }
        drugReferrals {
          therapyId
          archiveOrder
          drugId
          drugName
          drugType
          firstTreatmentDate
          inventorySource
          isCompleted
          lastTreatmentDate
          noOfTreatments
          originalReceivedDate
          patientHasStartedTherapy
          prescriberId
          reasonForArchiving
          referralApproved
          referralId
          referralUUID
          referralType
          rxOrderFile
          scheduling
          specialPharmName
          specialPharmPhoneNumber
          referralOrder {
            orderName
            orderType
            orderingProvider
            orderDate
            orderExpires
            primaryDX {
              primaryDiagnosis
              description
              diagnosedBy
            }
            otherDX {
              description
              diagnosedBy
              primaryDiagnosis
            }
            medicationType
            administrations {
              adminSequenceNumber
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              calcDosage
              administer
              dosageFrequencyType
              dosageDayRange
              dosageEvery
              dosageDateTimeFrameEvery
              dosageFor
              dosageDateTimeFrameFor
              dose
            }
            preMedications {
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              administer
              isPreMed
            }
            notes
          }

          orderNotes {
            allergies {
              allergen
              entered
              reaction
              recordNumber
            }
            labTests {
              date
              note
              type
              drugName
              labTest
              labExpiration
              results
            }
            oralLeadIn {
              dateOfFirstInjection
              dateOfLastDose
            }
          }

          adverseEvent {
            date
            drugName
            details
          }

          clinical {
            clinicalNotes
            orderApproved
            orderDenied
            orderPending
            reason
            expirationDateOfApproval
          }

          discontinuation {
            reasons {
              details
              reasonType
            }
            notes
            patientStartedTherapy
            survey
            agentId
            date
          }
        }
      }

      benefitInvestigation {
        benefitCheckings {
          referralId
          welcomeCallCompleted
          callCompletionTime
          checkings {
            selectedLocationId
            isCompleted
            insuranceKey
            paRequired
            verifiedDate
            billingTaxId
            billingOverrideReason
            billingNPINumber
            groupId
            selectedBillingNPI
            selectedBillingTaxId
            selectedGroupId
            selectedProviderId
            policy {
              coPay
            }
          }
        }
      }

      priorAuthorization {
        denialTrackings {
          referralId
          insuranceKey
          denialReason
          mdoContacted
        }
        freeDrugs {
          deliveryDate
          orderDate
          orderNumber
          orderedQuantity
          pharmacyName

          referralId
          representativeName
        }
        priorAuthCheckings {
          referralId
          insuranceKey
          isCompleted
          approvalInfo {
            isSiteSpecific
            priorAuthNumber
            serviceFrom
            serviceTo
            numberOfApprovedUnits
            numberOfApprovedVisits
          }
          requestHistory {
            insuranceCompanyName
            personSpokeWith
            callReference
            submittedDate
            followUpDate
          }
          welcomeCalls {
            callTime
            agentId
            answered
          }
        }
      }

      surveys {
        collectedAt
        collectedBy
        description
        survey
        typeOfSurvey
        version
      }
    }
  }
`;

export const listPatientBuckets = /* GraphQL */ `
  query ListPatientBuckets(
    $patientId: ID
    $filter: ModelPatientBucketFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPatientBuckets(
      patientId: $patientId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        agentId
        patientId
        patientFirstName
        patientLastName
        homePhoneNumber
        dob
        aggregateAttribute
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPrescriberInfo = /* GraphQL */ `
  query GetPrescriberInfo($NPINumber: ID!) {
    getPrescriberInfo(NPINumber: $NPINumber) {
      preferredPrescriberContactMethod
      medicalSpecialty
      needTreatmentRecords
      dontSendSystemGeneratedFaxes
      officeContactFirstName
      officeContactLastName
      siteInstitutionName
      taxIDNumber
      officeEmail
      officePhoneNumber
      officeFaxNumber
      prescriberFirstName
      prescriberMiddleName
      prescriberLastName
      HINNumber
      officeContactName
      NPINumber
      createdAt
      updatedAt
      officeAddresses {
        city
        state
        streetName
        county
        zip
        id
        phoneNumber
        faxNumber
        default
        officeContactName
        addressTitle
      }
      notes {
        date
        type
        note
        drugName
        labTest
        labExpiration
      }
    }
  }
`;

export const listPrescriberInfos = /* GraphQL */ `
  query ListPrescriberInfos(
    $NPINumber: ID
    $filter: ModelPrescriberInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrescriberInfos(
      NPINumber: $NPINumber
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        medicalSpecialty
        officeContactFirstName
        officeContactLastName
        siteInstitutionName
        taxIDNumber
        officeEmail
        officePhoneNumber
        officeFaxNumber
        prescriberFirstName
        prescriberMiddleName
        prescriberLastName
        HINNumber
        officeContactName
        NPINumber
        createdAt
        updatedAt
        officeAddresses {
          city
          state
          streetName
          county
          zip
          id
          phoneNumber
          faxNumber
          default
          officeContactName
          addressTitle
        }
        notes {
          date
          type
          note
          drugName
          labTest
          labExpiration
        }
      }
      nextToken
    }
  }
`;

export const getPrescribers = /* GraphQL */ `
  query getPrescribers($nextToken: String, $limit: Int) {
    listPrescriberInfos(
      nextToken: $nextToken
      limit: $limit
      filter: {
        prescriberLastName: { attributeExists: true }
        prescriberFirstName: { attributeExists: true }
      }
    ) {
      items {
        medicalSpecialty
        officeContactFirstName
        officeContactLastName
        siteInstitutionName
        taxIDNumber
        officeEmail
        officePhoneNumber
        officeFaxNumber
        prescriberFirstName
        prescriberMiddleName
        prescriberLastName
        HINNumber
        officeContactName
        NPINumber
        createdAt
        updatedAt
        officeAddresses {
          city
          state
          streetName
          county
          zip
          id
          phoneNumber
          faxNumber
          default
          officeContactName
          addressTitle
        }
        notes {
          date
          type
          note
          drugName
          labTest
          labExpiration
        }
      }
      nextToken
    }
  }
`;

export const listProducts = /* GraphQL */ `
  query ListProducts($nextToken: String) {
    listProducts(nextToken: $nextToken) {
      items {
        category
        dosing
        frequency
        isReconstituted
        jCode
        ndcInnerPackageCode
        ndcOuterPackageCode
        packageType
        packageVolume
        packageVolumeUOM
        possibleUOMs
        price
        productId
        productName
        route
        scheduledAllotment
        status
        strength
        typeOfDrug
        typeOfProduct
        unitOfMeas
        vendor
      }
      nextToken
    }
  }
`;

export const getProductList = /* GraphQL */ `
  query GetProductList {
    listProducts(limit: 10000) {
      items {
        category
        dosing
        frequency
        isReconstituted
        jCode
        ndcInnerPackageCode
        ndcOuterPackageCode
        packageType
        packageVolume
        packageVolumeUOM
        possibleUOMs
        price
        productId
        productName
        programId
        route
        scheduledAllotment
        status
        strength
        typeOfDrug
        typeOfProduct
        unitOfMeas
        vendor
      }
    }
  }
`;

export const getPatientInsuranceInfo = /* GraphQL */ `
  query GetPatientInsuranceInfo($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      agentId
      patientId
      patientProfile {
        insuranceInfo {
          isPatientInsured
          primary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            lastName
            groupId
            insuranceExpireDate
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
            dateOfVerification
            verifiedInsurance
          }
          secondary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            lastName
            groupId
            insuranceExpireDate
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
            dateOfVerification
            verifiedInsurance
          }
          tertiary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            lastName
            groupId
            insuranceExpireDate
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
            payerId
            dateOfVerification
            verifiedInsurance
          }
        }
      }
    }
  }
`;

export const getPatientHcpProfiles = /* GraphQL */ `
  query GetPatientHcpProfiles($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      agentId
      patientId
      hcpProfile {
        items {
          prescriber {
            prescriberFirstName
            prescriberLastName
            HINNumber
            NPINumber
            needTreatmentRecords
            dontSendSystemGeneratedFaxes
            taxIDNumber
            medicalSpecialty
            officeFaxNumber
            siteInstitutionName
            officePhoneNumber
            officeEmail
            officeContactFirstName
            officeContactName
            officeContactLastName
            preferredPrescriberContactMethod
            officeAddresses {
              city
              state
              streetName
              county
              zip
              id
              phoneNumber
              faxNumber
              default
              officeContactName
              addressTitle
            }
            notes {
              date
              drugName
              labExpiration
              labTest
              note
              type
              createdBy
            }
          }
          patientId
        }
      }
    }
  }
`;

// [MM]
// clinical {
//   allergies: [AllergyInput]
//   drugHistory: [DrugUsageInput]
//   dxCodeForPrescription
//   infusionProvider: InfusionProviderInput
//   medicationsUsedInTherapy
//   otherConditions: [ConditionInput]
//   prescription: PrescriptionInput
//   primaryDiagnosisInfo
//   secondaryDiagnosisInfo
//   stageOfTherapy
//   therapyEndDate
//   therapyStartDate
//   clinicalNotes: [ClinicalNoteInput]
// }
// adverseEvent {
//
// }
// discontinuation {
//
// }
export const getPatientReferralOrders = /* GraphQL */ `
  query GetPatientReferralOrders($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      agentId
      hcpProfile {
        items {
          prescriber {
            prescriberFirstName
            prescriberLastName
            NPINumber
            officeAddresses {
              city
              county
              default
              faxNumber
              id
              phoneNumber
              state
              zip
              streetName
              officeContactName
              addressTitle
            }
          }
          prescriberId
        }
      }
      patientId
      priorAuthorization {
        freeDrugs {
          deliveryDate
          orderDate
          orderNumber
          orderedQuantity
          pharmacyName

          referralId
          representativeName
        }
      }
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      patientProfile {
        insuranceInfo {
          primary {
            payerId
            insurerId
            planName
          }
        }
      }
      referral {
        archivedDrugReferrals {
          agentId
          archivedDate
          orderName
          archivedReferral {
            referralType
            referralUUID
            referralId
            noOfTreatments
            prescriberId
            originalReceivedDate
            therapyId
            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }
        drugReferrals {
          referralType
          referralUUID
          referralId
          drugId
          drugName
          specialPharmName
          specialPharmPhoneNumber
          rxOrderFile
          originalReceivedDate
          prescriberId
          drugType

          patientHasStartedTherapy
          prescriberOfficeAddressId
          noOfTreatments

          firstTreatmentDate
          lastTreatmentDate
          inventorySource
          specialPharmName
          specialPharmPhoneNumber
          referralApproved
          scheduling
          archiveOrder
          reasonForArchiving
          isCompleted
          therapyId
          referralOrder {
            referralOrderUUID
            orderName
            orderType
            orderingProvider
            orderDate
            orderExpires
            patientWeight
            unitOfWeight
            primaryDX {
              primaryDiagnosis
              description
              diagnosedBy
            }
            otherDX {
              primaryDiagnosis
              description
              diagnosedBy
            }
            medicationType
            administrations {
              adminSequenceNumber
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              calcDosage
              administer
              dosageFrequencyType
              dosageDayRange
              dosageEvery
              dosageDateTimeFrameEvery
              dosageFor
              dosageDateTimeFrameFor
              dose
            }
            preMedications {
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              administer
              isPreMed
            }
            notes
          }

          orderNotes {
            allergies {
              allergen
              entered
              reaction
              recordNumber
            }
            labTests {
              date
              note
              type
              drugName
              labTest
              labExpiration
              results
            }
            oralLeadIn {
              dateOfFirstInjection
              dateOfLastDose
            }
          }

          adverseEvent {
            date
            drugName
            details
          }

          clinical {
            orderApproved
            orderDenied
            orderPending
            reason
            expirationDateOfApproval
            clinicalNotes
          }

          discontinuation {
            reasons {
              details
              reasonType
            }
            notes
            patientStartedTherapy
            survey
            agentId
            date
          }
        }
      }
      benefitInvestigation {
        benefitCheckings {
          referralId
          welcomeCallCompleted
          callCompletionTime
          checkings {
            selectedLocationId
            isCompleted
            insuranceKey
            paRequired
            verifiedDate
            billingTaxId
            billingOverrideReason
            billingNPINumber
            groupId
            selectedBillingNPI
            selectedBillingTaxId
            selectedGroupId
            selectedProviderId
            policy {
              coPay
              planName
            }
          }
        }
      }
    }
  }
`;

// [MM]
// orderType
// orderDate
// orderExpires
// notes
export const getArchivedReferralOrders = /* GraphQL */ `
  query GetArchivedReferralOrders($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      agentId
      patientId
      referral {
        archivedDrugReferrals {
          agentId
          archivedDate
          orderName
          archivedReferral {
            therapyId
            referralId
            referralUUID
            drugId
            drugName
            drugType
            prescriberId
            noOfTreatments
            reasonForArchiving
            patientHasStartedTherapy
            firstTreatmentDate
            lastTreatmentDate
            scheduling
            inventorySource
            specialPharmName
            specialPharmPhoneNumber
            referralApproved
            archiveOrder
            isCompleted
            rxOrderFile
            originalReceivedDate
            discontinued
            prescriberOfficeAddressId

            referralOrder {
              orderName
              orderType
              orderDate
              orderExpires
              notes

              administrations {
                adminSequenceNumber
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                calcDosage
                administer
                dosageFrequencyType
                dosageDayRange
                dosageEvery
                dosageDateTimeFrameEvery
                dosageFor
                dosageDateTimeFrameFor
                dose
              }

              preMedications {
                drugName
                approvedDosage
                unitOfMeas
                maxOfTreatments
                route
                administer
                isPreMed
              }

              primaryDX {
                primaryDiagnosis
                description
                diagnosedBy
              }
              otherDX {
                description
                diagnosedBy
                primaryDiagnosis
              }
            }

            orderNotes {
              allergies {
                allergen
                entered
                reaction
                recordNumber
              }
              labTests {
                date
                drugName
                note
                type
                labTest
                labExpiration
                results
              }
              oralLeadIn {
                dateOfFirstInjection
                dateOfLastDose
              }
            }

            adverseEvent {
              date
              drugName
              details
            }

            clinical {
              orderApproved
              orderDenied
              reason
              expirationDateOfApproval
            }

            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }
      }
    }
  }
`;

// query GetProductOrdersByLocation($locationId: String! $period: DateRange! ) {
//  getProductOrdersByLocation(locationId: $locationId period: $period) {

export const getProductOrdersByLocation = /* GraphQL */ `
  query GetProductOrdersByLocation($locationId: String!) {
    getProductOrdersByLocation(locationId: $locationId) {
      orders {
        dose
        id
        initialQuantity
        locationId
        orderDate
        locationName
        orderQuantity
        orderStatus
        orderedBy
        productId
        unitOfMeasure
        productName
        updatedAt
        updatedBy
      }
    }
  }
`;

export const getCase = /* GraphQL */ `
  query GetCase($caseId: ID!) {
    getCase(caseId: $caseId) {
      logs {
        items {
          activityName
          agentId
          attachedData
          caseId
          description
          id
          loggingTime
          patientId
        }
      }
    }
  }
`;

export const getCase2 = /* GraphQL */ `
  query GetCase2($caseId: ID!) {
    getCase2(caseId: $caseId) {
      activities {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      currentActivity {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      caseId
      caseStatus
      currentAssignedAgentId
      description
      patientFirstName
      followUpDate
      patientLastName
      patientId
      documentURI
      agentLastName
      agentFirstName
      alertLevels {
        intake
        bi
        pa
        updateDate
        updatedBy
      }
      statusDetails {
        isIntakeCompleted
        isBICompleted
        isPACompleted
        updateDate
      }
      source
      isLocked
      lockedAt
      lockedBy
      createdAt
      updatedAt
    }
  }
`;

export const listCases = /* GraphQL */ `
  query ListCases(
    $caseId: ID
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCases(
      caseId: $caseId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        caseId
        caseStatus
        currentAssignedAgentId
        description
        patientFirstName
        followUpDate
        patientLastName
        patientId
        documentURI
        agentLastName
        agentFirstName
        source
        isLocked
        lockedAt
        lockedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $caseId: ID
  ) {
    listActivitys(filter: {caseId: {eq: $caseId}}, limit: 100) {
    {
      items {
        activityName
        agentId
        attachedData
        caseId
        description
        id
        loggingTime
        patientId
      }
    }
  }
`;

export const getCommunicationHistory = /* GraphQL */ `
  query GetCommunicationHistory($id: ID!) {
    getCommunicationHistory(id: $id) {
      id
      patientId
      agentId
      caseId
      eventTime
      updateTime
      channel
      fromEntity
      toEntity
      description
      reference
      purpose
      direction
      status
      faxId
      createdAt
      updatedAt
    }
  }
`;

export const listCommunicationHistory = /* GraphQL */ `
  query ListCommunicationHistory(
    $id: ID
    $filter: ModelCommunicationHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCommunicationHistorys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        agentEmail
        agentId
        attachment
        caseId
        channel
        description
        direction
        emailCCList
        eventTime
        faxId
        fromEntity
        patientId
        purpose
        reference
        status
        subject
        toEntity
        updateTime
      }
    }
  }
`;

export const GetCommunicationHistoryByPatientId = /* GraphQL */ `
  query getCommunicationHistoryByPatientId(
    $patientId: String!
    $filter: ModelCommunicationHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getCommunicationHistoryByPatientId(
      patientId: $patientId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        agentEmail
        agentId
        attachment
        caseId
        channel
        description
        direction
        emailCCList
        eventTime
        faxId
        fromEntity
        id
        patientId
        purpose
        reference
        retrials
        status
        subject
        toEntity
        updateTime
      }
      nextToken
    }
  }
`;

export const getAgentByQueueDepthAgentId = /* GraphQL */ `
  query GetAgentByQueueDepthAgentId(
    $queueDepth: Int
    $agentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAgentByQueueDepthAgentId(
      queueDepth: $queueDepth
      agentId: $agentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        agentId
        queueDepth
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgentByRoleAgentId = /* GraphQL */ `
  query GetAgentByRoleAgentId(
    $role: String
    $agentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAgentByRoleAgentId(
      role: $role
      agentId: $agentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        agentId
        queueDepth
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgentByRoleQueueDepth = /* GraphQL */ `
  query GetAgentByRoleQueueDepth(
    $role: String
    $queueDepth: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAgentByRoleQueueDepth(
      role: $role
      queueDepth: $queueDepth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        agentId
        queueDepth
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPatientBucketByLastNameDob = /* GraphQL */ `
  query GetPatientBucketByLastNameDob(
    $patientLastName: String
    $dob: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientBucketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPatientBucketByLastNameDob(
      patientLastName: $patientLastName
      dob: $dob
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        agentId
        dob
        patientFirstName
        homePhoneNumber
        patientLastName
        patientId
        aggregateAttribute
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPatientBucketByLastNamePhone = /* GraphQL */ `
  query GetPatientBucketByLastNamePhone(
    $patientLastName: String
    $homePhoneNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientBucketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPatientBucketByLastNamePhone(
      patientLastName: $patientLastName
      homePhoneNumber: $homePhoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        agentId
        patientFirstName
        homePhoneNumber
        patientLastName
        patientId
      }
      nextToken
    }
  }
`;

export const getPatientBucketByDob = /* GraphQL */ `
  query GetPatientBucketByDob(
    $dob: AWSDate!
    $filter: ModelPatientBucketFilterInput
  ) {
    getPatientBucketByDob(dob: $dob, filter: $filter) {
      items {
        agentId
        patientId
        patientFirstName
        patientLastName
        patientStatus
        dob
        homePhoneNumber

        patientProfile {
          patientInfo {
            preferredContactMethod
            address {
              city
              state
              streetName
              zip
            }
            email
            gender
            patientWeightLB
            cellphoneNumber
            preferredLanguage
            bestContact
            alternateContact {
              firstName
              lastName
              relationship
              phone
            }
            toContactPatient
            toLeaveMessage
            bestTimeToContact
            ssn
            hipaaContact
            preference {
              surveys {
                preferred
                updatedAt
              }
            }
          }

          insuranceInfo {
            isPatientInsured
            primary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            secondary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            tertiary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
          }

          allergyInfo {
            allergen
            entered
            reaction
            recordNumber
          }

          medicationInfo {
            discontinuationDate
            dosage
            frequency
            medication
            recordNumber
            startDate
          }

          weightInfo {
            changeFromLastKnown
            entered
            lastKnown
            recordNumber
            unitOfWeight
            weight
            weightLB
          }
        }

        hcpProfile {
          items {
            prescriberId
            patientId
            prescriber {
              NPINumber
              prescriberLastName
              prescriberFirstName
              taxIDNumber
              updatedAt
              officePhoneNumber
              officeFaxNumber
              needTreatmentRecords
              dontSendSystemGeneratedFaxes
              officeAddresses {
                city
                state
                streetName
                county
                zip
                id
                phoneNumber
                faxNumber
                default
                officeContactName
                addressTitle
              }
            }
          }
        }

        patientAssistantProgram {
          annualIncome
          eligibleForCoPay
          householdSize
          incomeDoc
        }

        patientDocuments {
          documentType
          documentPath
          date
          receivedAt
        }

        notes {
          date
          type
          note
          createdBy
        }

        updatedAt

        referral {
          archivedDrugReferrals {
            agentId
            archivedDate
            orderName
            archivedReferral {
              referralType
              referralId
              noOfTreatments
              prescriberId
              discontinuation {
                reasons {
                  details
                  reasonType
                }
                notes
                patientStartedTherapy
                survey
                agentId
                date
              }
            }
          }
          drugReferrals {
            referralType
            drugId
            drugName
            specialPharmName
            specialPharmPhoneNumber

            prescriberId
            drugType

            patientHasStartedTherapy

            noOfTreatments

            firstTreatmentDate
            lastTreatmentDate
            inventorySource
            specialPharmName
            specialPharmPhoneNumber
            referralApproved
            scheduling
            archiveOrder
            reasonForArchiving
            isCompleted
            originalReceivedDate

            referralOrder {
              orderName
              orderType
              orderingProvider
              orderDate
              orderExpires
              primaryDX {
                primaryDiagnosis
                description
                diagnosedBy
              }
              otherDX {
                description
                diagnosedBy
                primaryDiagnosis
              }
              medicationType
              administrations {
                adminSequenceNumber
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                calcDosage
                administer
                dosageFrequencyType
                dosageDayRange
                dosageEvery
                dosageDateTimeFrameEvery
                dosageFor
                dosageDateTimeFrameFor
                dose
              }
              preMedications {
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                administer
                isPreMed
              }
              notes
            }

            orderNotes {
              allergies {
                allergen
                entered
                reaction
                recordNumber
              }
              labTests {
                date
                note
                type
                drugName
                labTest
                labExpiration
                results
              }
              oralLeadIn {
                dateOfFirstInjection
                dateOfLastDose
              }
            }

            adverseEvent {
              date
              drugName
              details
            }

            clinical {
              orderApproved
              orderDenied
              reason
              expirationDateOfApproval
            }

            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }

        benefitInvestigation {
          benefitCheckings {
            referralId
            welcomeCallCompleted
            callCompletionTime
            checkings {
              selectedLocationId
              isCompleted
              insuranceKey
              paRequired
              verifiedDate
              billingTaxId
              billingOverrideReason
              billingNPINumber
              groupId
              selectedBillingNPI
              selectedBillingTaxId
              selectedGroupId
              selectedProviderId
              policy {
                coPay
              }
            }
          }
        }

        priorAuthorization {
          denialTrackings {
            referralId
            insuranceKey
            denialReason
            mdoContacted
          }
          freeDrugs {
            deliveryDate
            orderDate
            orderNumber
            orderedQuantity
            pharmacyName

            referralId
            representativeName
          }
          priorAuthCheckings {
            referralId
            isCompleted
            approvalInfo {
              priorAuthNumber
              serviceFrom
              serviceTo
              numberOfApprovedUnits
              numberOfApprovedVisits
              isSiteSpecific
            }
            requestHistory {
              insuranceCompanyName
              personSpokeWith
              callReference
              submittedDate
              followUpDate
            }
            welcomeCalls {
              callTime
              agentId
              answered
            }
          }
        }
      }
    }
  }
`;

export const getPatientBucketByLastName = /* GraphQL */ `
  query GetPatientBucketByLastName(
    $patientLastName: String!
    $filter: ModelPatientBucketFilterInput
  ) {
    getPatientBucketByLastName(
      patientLastName: $patientLastName
      filter: $filter
    ) {
      items {
        bulletinBoard
        agentId
        patientId
        patientFirstName
        patientLastName
        patientStatus
        dob
        homePhoneNumber

        patientProfile {
          patientInfo {
            preferredContactMethod
            address {
              city
              state
              streetName
              zip
            }
            email
            gender
            patientWeightLB
            cellphoneNumber
            preferredLanguage
            bestContact
            alternateContact {
              firstName
              lastName
              relationship
              phone
            }
            toContactPatient
            toLeaveMessage
            bestTimeToContact
            ssn
            hipaaContact
            preference {
              surveys {
                preferred
                updatedAt
              }
            }
          }

          insuranceInfo {
            isPatientInsured
            primary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            secondary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            tertiary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
          }

          allergyInfo {
            allergen
            entered
            reaction
            recordNumber
          }

          medicationInfo {
            discontinuationDate
            dosage
            frequency
            medication
            recordNumber
            startDate
          }

          weightInfo {
            changeFromLastKnown
            entered
            lastKnown
            recordNumber
            unitOfWeight
            weight
            weightLB
          }
        }

        hcpProfile {
          items {
            prescriberId
            patientId
            prescriber {
              NPINumber
              prescriberLastName
              prescriberFirstName
              taxIDNumber
              updatedAt
              needTreatmentRecords
              dontSendSystemGeneratedFaxes
              officePhoneNumber
              officeFaxNumber
              officeAddresses {
                city
                state
                streetName
                county
                zip
                id
                phoneNumber
                faxNumber
                default
                officeContactName
                addressTitle
              }
            }
          }
        }

        patientAssistantProgram {
          annualIncome
          eligibleForCoPay
          householdSize
          incomeDoc
        }

        patientDocuments {
          documentType
          documentPath
          date
          receivedAt
        }

        notes {
          date
          type
          note
          createdBy
        }

        updatedAt

        referral {
          archivedDrugReferrals {
            agentId
            archivedDate
            orderName
            archivedReferral {
              referralType
              referralId
              noOfTreatments
              prescriberId
              originalReceivedDate
              discontinuation {
                reasons {
                  details
                  reasonType
                }
                notes
                patientStartedTherapy
                survey
                agentId
                date
              }
            }
          }
          drugReferrals {
            referralType
            drugId
            drugName
            specialPharmName
            specialPharmPhoneNumber
            referralId
            prescriberId
            drugType
            patientHasStartedTherapy
            noOfTreatments
            firstTreatmentDate
            lastTreatmentDate
            inventorySource
            specialPharmName
            specialPharmPhoneNumber
            referralApproved
            scheduling
            archiveOrder
            reasonForArchiving
            isCompleted
            originalReceivedDate
            referralOrder {
              orderName
              orderType
              orderingProvider
              orderDate
              orderExpires
              primaryDX {
                primaryDiagnosis
                description
                diagnosedBy
              }
              otherDX {
                description
                diagnosedBy
                primaryDiagnosis
              }
              medicationType
              administrations {
                adminSequenceNumber
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                calcDosage
                administer
                dosageFrequencyType
                dosageDayRange
                dosageEvery
                dosageDateTimeFrameEvery
                dosageFor
                dosageDateTimeFrameFor
                dose
              }
              preMedications {
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                administer
                isPreMed
              }
              notes
            }

            orderNotes {
              allergies {
                allergen
                entered
                reaction
                recordNumber
              }
              labTests {
                date
                note
                type
                drugName
                labTest
                labExpiration
                results
              }
              oralLeadIn {
                dateOfFirstInjection
                dateOfLastDose
              }
            }

            adverseEvent {
              date
              drugName
              details
            }

            clinical {
              orderApproved
              orderDenied
              reason
              expirationDateOfApproval
            }

            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }

        benefitInvestigation {
          benefitCheckings {
            referralId
            welcomeCallCompleted
            callCompletionTime
            checkings {
              selectedLocationId
              isCompleted
              insuranceKey
              paRequired
              verifiedDate
              billingTaxId
              billingOverrideReason
              billingNPINumber
              groupId
              selectedBillingNPI
              selectedBillingTaxId
              selectedGroupId
              selectedProviderId
              policy {
                coPay
              }
            }
          }
        }

        priorAuthorization {
          denialTrackings {
            referralId
            insuranceKey
            denialReason
            mdoContacted
          }
          freeDrugs {
            deliveryDate
            orderDate
            orderNumber
            orderedQuantity
            pharmacyName

            referralId
            representativeName
          }
          priorAuthCheckings {
            referralId
            isCompleted
            approvalInfo {
              priorAuthNumber
              serviceFrom
              serviceTo
              numberOfApprovedUnits
              numberOfApprovedVisits
              isSiteSpecific
            }
            requestHistory {
              insuranceCompanyName
              personSpokeWith
              callReference
              submittedDate
              followUpDate
            }
            welcomeCalls {
              callTime
              agentId
              answered
            }
          }
        }
      }
    }
  }
`;

export const getPatientBucketByLastNameInLowerCase = /* GraphQL */ `
  query GetPatientBucketByLastNameInLowerCase(
    $sPatientLastName: String!
    $filter: ModelPatientBucketFilterInput
    $nextToken: String
  ) {
    getPatientBucketByLastNameInLowerCase(
      sPatientLastName: $sPatientLastName
      filter: $filter
      nextToken: $nextToken
    ) {
      nextToken
      items {
        bulletinBoard
        agentId
        patientId
        patientFirstName
        patientLastName
        patientStatus
        dob
        homePhoneNumber

        patientProfile {
          patientInfo {
            preferredContactMethod
            address {
              city
              state
              streetName
              zip
            }
            email
            gender
            patientWeightLB
            cellphoneNumber
            preferredLanguage
            bestContact
            alternateContact {
              firstName
              lastName
              relationship
              phone
            }
            toContactPatient
            toLeaveMessage
            bestTimeToContact
            ssn
            hipaaContact
            preference {
              surveys {
                preferred
                updatedAt
              }
            }
          }

          insuranceInfo {
            isPatientInsured
            primary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            secondary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
            tertiary {
              insurerId
              insurerName
              binNumber
              pcnNumber
              coveredBy
              email
              firstName
              groupId
              insuranceExpireDate
              lastName
              planName
              policyId
              state
              customerServicePhone
              relationship
              rxPlanName
              pharmacyPhone
              rxGroupNumber
              hasPharmacyBenefits
              payerId
            }
          }

          allergyInfo {
            allergen
            entered
            reaction
            recordNumber
          }

          medicationInfo {
            discontinuationDate
            dosage
            frequency
            medication
            recordNumber
            startDate
          }

          weightInfo {
            changeFromLastKnown
            entered
            lastKnown
            recordNumber
            unitOfWeight
            weight
            weightLB
          }
        }

        hcpProfile {
          items {
            prescriberId
            patientId
            prescriber {
              NPINumber
              prescriberLastName
              prescriberFirstName
              taxIDNumber
              updatedAt
              needTreatmentRecords
              dontSendSystemGeneratedFaxes
              officePhoneNumber
              officeFaxNumber
              officeAddresses {
                city
                state
                streetName
                county
                zip
                id
                phoneNumber
                faxNumber
                default
                officeContactName
                addressTitle
              }
            }
          }
        }

        patientAssistantProgram {
          annualIncome
          eligibleForCoPay
          householdSize
          incomeDoc
        }

        patientDocuments {
          documentType
          documentPath
          date
          receivedAt
        }

        notes {
          date
          type
          note
          createdBy
        }

        updatedAt

        referral {
          archivedDrugReferrals {
            agentId
            archivedDate
            orderName
            archivedReferral {
              referralType
              originalReceivedDate
              referralId
              noOfTreatments
              prescriberId
              discontinuation {
                reasons {
                  details
                  reasonType
                }
                notes
                patientStartedTherapy
                survey
                agentId
                date
              }
            }
          }
          drugReferrals {
            referralType
            drugId
            drugName
            specialPharmName
            specialPharmPhoneNumber
            referralId
            prescriberId
            drugType
            patientHasStartedTherapy
            noOfTreatments
            firstTreatmentDate
            lastTreatmentDate
            inventorySource
            specialPharmName
            specialPharmPhoneNumber
            referralApproved
            scheduling
            archiveOrder
            reasonForArchiving
            isCompleted
            originalReceivedDate
            referralOrder {
              orderName
              orderType
              orderingProvider
              orderDate
              orderExpires
              primaryDX {
                primaryDiagnosis
                description
                diagnosedBy
              }
              otherDX {
                description
                diagnosedBy
                primaryDiagnosis
              }
              medicationType
              administrations {
                adminSequenceNumber
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                calcDosage
                administer
                dosageFrequencyType
                dosageDayRange
                dosageEvery
                dosageDateTimeFrameEvery
                dosageFor
                dosageDateTimeFrameFor
                dose
              }
              preMedications {
                drugName
                maxOfTreatments
                route
                unitOfMeas
                approvedDosage
                administer
                isPreMed
              }
              notes
            }

            orderNotes {
              allergies {
                allergen
                entered
                reaction
                recordNumber
              }
              labTests {
                date
                note
                type
                drugName
                labTest
                labExpiration
                results
              }
              oralLeadIn {
                dateOfFirstInjection
                dateOfLastDose
              }
            }

            adverseEvent {
              date
              drugName
              details
            }

            clinical {
              orderApproved
              orderDenied
              reason
              expirationDateOfApproval
            }

            discontinuation {
              reasons {
                details
                reasonType
              }
              notes
              patientStartedTherapy
              survey
              agentId
              date
            }
          }
        }

        benefitInvestigation {
          benefitCheckings {
            referralId
            welcomeCallCompleted
            callCompletionTime
            checkings {
              selectedLocationId
              isCompleted
              insuranceKey
              paRequired
              verifiedDate
              billingTaxId
              billingOverrideReason
              billingNPINumber
              groupId
              selectedBillingNPI
              selectedBillingTaxId
              selectedGroupId
              selectedProviderId
              policy {
                coPay
              }
            }
          }
        }

        priorAuthorization {
          denialTrackings {
            referralId
            insuranceKey
            denialReason
            mdoContacted
          }
          freeDrugs {
            deliveryDate
            orderDate
            orderNumber
            orderedQuantity
            pharmacyName

            referralId
            representativeName
          }
          priorAuthCheckings {
            referralId
            isCompleted
            approvalInfo {
              priorAuthNumber
              serviceFrom
              serviceTo
              numberOfApprovedUnits
              numberOfApprovedVisits
              isSiteSpecific
            }
            requestHistory {
              insuranceCompanyName
              personSpokeWith
              callReference
              submittedDate
              followUpDate
            }
            welcomeCalls {
              callTime
              agentId
              answered
            }
          }
        }
      }
    }
  }
`;

export const getBucketByAgg = /* GraphQL */ `
  query GetBucketByAgg(
    $aggregateAttribute: String
    $sortDirection: ModelSortDirection
    $filter: ModelPatientBucketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBucketByAgg(
      aggregateAttribute: $aggregateAttribute
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        agentId
        dob
        patientFirstName
        homePhoneNumber
        patientLastName
        patientId
        aggregateAttribute
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

// export const getCaseByPatientIdAgentId = /* GraphQL */ `
//   query GetCaseByPatientIdAgentId(
//     $patientId: ID
//     $currentAssignedAgentId: ModelStringKeyConditionInput
//     $sortDirection: ModelSortDirection
//     $filter: ModelCaseFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     getCaseByPatientIdAgentId(
//       patientId: $patientId
//       currentAssignedAgentId: $currentAssignedAgentId
//       sortDirection: $sortDirection
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         caseId
//         caseStatus
//         currentAssignedAgentId
//         description
//         patientFirstName
//         followUpDate
//         patientLastName
//         patientId
//         documentURI
//         agentLastName
//         agentFirstName
//         source
//         isLocked
//         lockedAt
//         lockedBy
//         createdAt
//         updatedAt
//       }
//       nextToken
//     }
//   }
// `;

export const getCaseByPatientIdAgentId = /* GraphQL */ `
  query GetCaseByPatientIdAgentId(
    $patientId: ID!
    $currentAssignedAgentId: String!
  ) {
    getCaseByPatientIdAgentId(
      patientId: $patientId
      currentAssignedAgentId: $currentAssignedAgentId
    ) {
      items {
        caseId
        caseStatus
        currentAssignedAgentId
        description
        patientId
        agentId
        documentURI
        source
        isLocked
        lockedAt
        lockedBy
        caseType
        createdAt
        updatedAt
      }
    }
  }
`;

export const getCasesByAgentIdAndStatus = /* GraphQL */ `
  query GetCasesByAgentIdAndStatus(
    $currentAssignedAgentId: String
    $caseStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCasesByAgentIdAndStatus(
      currentAssignedAgentId: $currentAssignedAgentId
      caseStatus: $caseStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        caseId
        caseStatus
        currentAssignedAgentId
        description
        patientFirstName
        followUpDate
        patientLastName
        patientId
        documentURI
        agentLastName
        agentFirstName
        source
        isLocked
        lockedAt
        lockedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistoryRecordsByStatus = /* GraphQL */ `
  query GetHistoryRecordsByStatus(
    $status: CommunicationStatus
    $sortDirection: ModelSortDirection
    $filter: ModelCommunicationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHistoryRecordsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        agentId
        caseId
        eventTime
        updateTime
        channel
        fromEntity
        toEntity
        description
        reference
        purpose
        direction
        status
        faxId
      }
      nextToken
    }
  }
`;

export const getHistoryRecordsByTime = /* GraphQL */ `
  query GetHistoryRecordsByTime(
    $status: CommunicationStatus
    $period: DateRange!
  ) {
    getHistoryRecordsByTime(status: $status, period: $period) {
      id
      agentEmail
      agentId
      attachment
      caseId
      causesForFailure
      channel
      description
      direction
      emailCCList
      eventTime
      faxId
      fromEntity
      updateTime
      toEntity
      subject
      status
      retrials
      referenceId
      reference
      purpose
      patientId
      notified
    }
  }
`;

export const getHistoryByFaxId = /* GraphQL */ `
  query GetHistoryByFaxId(
    $faxId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCommunicationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHistoryByFaxId(
      faxId: $faxId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        agentId
        caseId
        eventTime
        updateTime
        channel
        fromEntity
        toEntity
        description
        reference
        purpose
        direction
        status
        faxId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPatientBucketByDoctorLastNameAndPhone = /* GraphQL */ `
  query GetPatientBucketByDoctorLastNameAndPhone(
    $doctorLastName: String
    $doctorPhoneNumber: ModelStringKeyConditionInput
  ) {
    getPatientBucketByDoctorLastNameAndPhone(
      doctorLastName: $doctorLastName
      doctorPhoneNumber: $doctorPhoneNumber
    ) {
      items {
        NPINumber
        agentId
        dob
        doctorFirstName
        doctorLastName
        doctorPhoneNumber
        drugId
        firstName
        homePhoneNumber
        lastName
        patientId
      }
    }
  }
`;

export const getPatientBucketByNpiNumber = /* GraphQL */ `
  query GetPatientBucketByNpiNumber($NPINumber: ID) {
    getPatientBucketByNPINumber(NPINumber: $NPINumber) {
      items {
        NPINumber
        agentId
        dob
        doctorFirstName
        doctorLastName
        doctorPhoneNumber
        drugId
        firstName
        homePhoneNumber
        lastName
        patientId
      }
    }
  }
`;
export const getPatientPayments = /* GraphQL */ `
  query GetPatientPayments($patientId: ID!) {
    getPatientPayments(patientId: $patientId) {
      primaryClaimId
      secondaryClaimId
      primaryBilledDate
      insurancePaidAmount
      dateOfService
      allowedAmount
    }
  }
`;

export const getBenefitChecking = /* GraphQL */ `
  query GetBenefitChecking(
    $patientId: ID!
    $referralId: String!
    $insuranceKey: String!
  ) {
    getBenefitChecking(
      patientId: $patientId
      referralId: $referralId
      insuranceKey: $insuranceKey
    ) {
      benefitChecking {
        referralId
        welcomeCalls {
          callTime
          agentId
          answered
        }
        welcomeCallCompleted
        callCompletionTime

        checkings {
          insuranceKey
          groupId
          billingTaxId
          billingNPINumber
          policyId
          policy {
            planType
            planName
            dedType
            effectiveDate
            termDate
            coPay
            coveragePercentage
            verificationMethod
            payerPhoneNumber
            networkStatus
            loa
            outOfNetworkBenefits
            deductibleNotes
            deductibleInfo {
              deductibleType
              networkStatus
              totalDeductibleAmount
              metDeductible {
                amount
                resetDate
              }
              totalOOPAmount
              metOOP {
                amount
                resetDate
              }
            }
            oopMax {
              deductibleType
              networkStatus
              totalDeductibleAmount
              metDeductible {
                amount
                resetDate
              }
              totalOOPAmount
              metOOP {
                amount
                resetDate
              }
            }
          }
          paRequired
          priorAuthorization {
            submitMethod
            paPhone
            paSite
            jCode
            adminCode1
            adminCode2
            adminCode3
          }
          predeterminationNeeded
          predetermination {
            submitMethod
            paPhone
            paSite
            jCode
            adminCode1
            adminCode2
            adminCode3
          }
          claims {
            claimAddress {
              streetName
              city
              state
              county
              zip
            }
            timelyFilling
            riskForClaims
            results
          }
          selectedGroupId
          selectedLocationId
          selectedProviderId
          selectedLocation
          selectedProvider
          selectedBillingTaxId
          selectedBillingNPI
          billingTaxIdForOutOfNetwork
          billingNPIForOutOfNetwork
          billingOverrideReason
          verifiedDate
          callCompletionTime
          callReferenceNumber
          representativeName
          isCompleted
        }
      }

      groupsAndAssociations {
        name
        taxId
        phoneNumber
        faxNumber
        locations {
          locationNPI
          locationName
          address {
            streetName
            city
            state
            county
            zip
          }
          state
          groupId
          providers {
            providerNPI
            locationId
            firstName
            lastName
            # sex
            type
          }
        }
      }

      insuranceInfo {
        insurerId
        insurerName
        binNumber
        pcnNumber
        coveredBy
        email
        firstName
        lastName
        groupId
        insuranceExpireDate
        planName
        policyId
        state
        customerServicePhone
        relationship
        rxPlanName
        pharmacyPhone
        rxGroupNumber
        hasPharmacyBenefits
        payerId
      }
    }
  }
`;
export const getBenefitCheckingHistory = /* GraphQL */ `
  query GetBenefitCheckingHistory($patientId: ID!, $recordId: String) {
    getBenefitCheckingHistory(patientId: $patientId, recordId: $recordId) {
      agentId
      drugName
      insurancePlan
      insuranceType

      recordId

      updatedBenefitChecking {
        callCompletionTime

        checking {
          billingNPIForOutOfNetwork
          billingNPINumber
          billingOverrideReason
          billingTaxId
          billingTaxIdForOutOfNetwork
          callCompletionTime
          callReferenceNumber
          representativeName
          claims {
            claimAddress {
              city
              state
              streetName
              zip
            }
            results
            timelyFilling
          }
          groupId
          insuranceKey
          isCompleted
          paRequired
          policy {
            coPay
            coveragePercentage
            dedType
            deductibleInfo {
              deductibleType
              metDeductible {
                amount
                resetDate
              }
              metOOP {
                amount
                resetDate
              }
              networkStatus

              totalDeductibleAmount
              totalOOPAmount
            }
            effectiveDate
            networkStatus
            oopMax {
              deductibleType
              metDeductible {
                amount
                resetDate
              }
              metOOP {
                amount
                resetDate
              }
              networkStatus

              totalDeductibleAmount
              totalOOPAmount
            }
            outOfNetworkBenefits
            planName
            planType
            termDate
            verificationMethod
            payerPhoneNumber
          }
          policyId
          predetermination {
            adminCode3
            adminCode2
            adminCode1
            jCode
            paPhone
            paSite
            submitMethod
          }
          predeterminationNeeded
          priorAuthorization {
            adminCode3
            adminCode2
            adminCode1
            jCode
            paPhone
            paSite
            submitMethod
          }
          selectedBillingNPI
          selectedBillingTaxId
          selectedGroupId
          selectedLocationId
          selectedProviderId
          verifiedDate
        }

        referralId

        welcomeCallCompleted
        welcomeCalls {
          agentId
          answered
          callTime
        }
      }

      verificationDate
    }
  }
`;

// priorAuthChecking: PriorAuthChecking
// groupAndAssociates: GroupResp
// associatedBIChecking: AssociatedBICheckingForPA
// associatedReferral: DrugReferral
// freeDrug: FreeDrug
// denialTracking: PriorAuthDenialTracking
// isPARequired: Boolean!
// isBICompleted: Boolean!
export const getPriorAuthorization = /* GraphQL */ `
  query GetPriorAuthorization(
    $patientId: ID!
    $referralId: String!
    $insuranceKey: String!
  ) {
    getPriorAuthorization(
      patientId: $patientId
      referralId: $referralId
      insuranceKey: $insuranceKey
    ) {
      # isPARequired: Boolean!
      isPARequired
      # isBICompleted: Boolean!
      isBICompleted
      # priorAuthChecking: PriorAuthChecking
      priorAuthChecking {
        welcomeCallCompleted
        welcomeCalls {
          agentId
          answered
          callTime
        }
        # callCompletionTime: AWSDateTime

        # isCompleted: Boolean!
        isCompleted
        # requestHistory: PARequestRecord
        requestHistory {
          jCodes {
            code
            units
          }
          billings {
            groupId
            locationId
            providerId
            inNetworkTIN
            inNetworkNPI
            outOfNetworkTIN
            outOfNetworkNPI
          }
          adminCodes {
            code
            units
          }
          submittedDate
          personSpokeWith
          insuranceCompanyName
          phoneNumber
          followUpDate
          callReference
        }
        # approvalInfo: ApprovalInfo
        approvalInfo {
          frequency {
            every
            everyUnit
            duration
            durationUnit
          }
          isSiteSpecific
          numberOfApprovedUnits
          numberOfApprovedVisits
          priorAuthNumber
          serviceFrom
          serviceTo
        }
        # stat: Boolean
        stat
        # referralId: String
        referralId
        # insuranceKey: String
        insuranceKey
        # authStatus: ApprovalStatus
        authStatus
        mdoContacted
        denialReason
      }
      # groupAndAssociates: GroupResp
      groupAndAssociates {
        id
        name
        taxId
        phoneNumber
        faxNumber
        locations {
          id
          state
          locationName
          locationNPI
          groupId
          address {
            city
            state
            streetName
            county
            zip
          }
          providers {
            providerNPI
            locationId
            firstName
            lastName
            # sex
            type
          }
        }
      }
      # denialTracking: PriorAuthDenialTracking
      denialTracking {
        referralId
        insuranceKey
        denialReason
        mdoContacted
      }
      # associatedReferral: DrugReferral
      associatedReferral {
        referralOrder {
          administrations {
            adminSequenceNumber
            drugName
            maxOfTreatments
            route
            unitOfMeas
            approvedDosage
            calcDosage
            administer
            dosageFrequencyType
            dosageDayRange
            dosageEvery
            dosageDateTimeFrameEvery
            dosageFor
            dosageDateTimeFrameFor
            dose
          }
          primaryDX {
            primaryDiagnosis
            description
            diagnosedBy
          }
          otherDX {
            primaryDiagnosis
            description
            diagnosedBy
          }

          preMedications {
            drugName
            maxOfTreatments
            route
            unitOfMeas
            approvedDosage
            administer
            isPreMed
          }
          medicationType
          notes
          orderDate
          orderExpires
          orderName
          orderType
          orderingProvider
        }
        orderNotes {
          labTests {
            date
            type
            note
            drugName
            labTest
            labExpiration
            results
          }
          allergies {
            allergen
            entered
            reaction
            recordNumber
          }
          oralLeadIn {
            dateOfFirstInjection
            dateOfLastDose
          }
        }
        discontinuation {
          reasons {
            details
            reasonType
          }
          notes
          patientStartedTherapy
          survey
          agentId
          date
        }
        clinical {
          orderApproved
          orderDenied
          reason
          expirationDateOfApproval
        }
        adverseEvent {
          date
          drugName
          details
        }
        specialPharmPhoneNumber
        specialPharmName
        scheduling
        referralId
        referralApproved
        reasonForArchiving
        prescriberId
        patientHasStartedTherapy
        noOfTreatments
        lastTreatmentDate
        isCompleted
        inventorySource
        firstTreatmentDate
        drugType
        drugName
        drugId
        archiveOrder
        approvedDosage
      }
      # associatedBIChecking: AssociatedBICheckingForPA
      associatedBIChecking {
        billingNPIForOutOfNetwork
        billingOverrideReason
        billingTaxIdForOutOfNetwork
        selectedBillingNPI
        selectedBillingTaxId
        selectedGroupId
        selectedLocationId
        selectedProviderId
      }
    }
  }
`;

export const getWorkItemsByGroupQueue = /* GraphQL */ `
  query GetWorkItemsByGroupQueue($groupQueueId: ID!, $lightWeight: Boolean) {
    getWorkItemsByGroupQueue(
      groupQueueId: $groupQueueId
      lightWeight: $lightWeight
    ) {
      id
      agentFirstName
      agentLastName
      assignedTo
      attachedData
      clinicianReviewStatus
      createdBy
      createdAt
      description
      documentURI
      drugName
      endTime
      followupDate
      groupQueueId
      payer
      prescriberFirstName
      prescriberLastName
      insurerName
      inProgram
      locationName
      patientFirstName
      patientId
      patientLastName
      patientState
      patientStatus
      priority
      referralId
      salesTerritoryName
      salesTerritoryId
      source
      state
      startTime
      targetTime
      work
      workStatus
      previousWorkStatus
    }
  }
`;
export const getWorkItemsByTaskType = /* GraphQL */ `
  query GetWorkItemsByTaskType($taskType: String!) {
    getWorkItemsByTaskType(taskType: $taskType) {
      workStatus
      work
      locationName
      patientFirstName
      patientLastName
      agentFirstName
      agentLastName
      assignedTo
      attachedData
      caseId
      clinicianReviewStatus
      createdAt
      createdBy
      description
      documentURI
      drugName
      endTime
      followupDate
      groupQueueId
      inProgram
      id
      patientId
      priority
      referralId
      source
      startTime
      targetTime
      previousWorkStatus
    }
  }
`;

export const getWorkItemsByGroupQueueLite = /* GraphQL */ `
  query GetWorkItemsByGroupQueue($groupQueueId: ID!, $lightWeight: Boolean) {
    getWorkItemsByGroupQueue(
      groupQueueId: $groupQueueId
      lightWeight: $lightWeight
    ) {
      id
      agentFirstName
      agentLastName
      assignedTo
      attachedData
      clinicianReviewStatus
      createdBy
      description
      documentURI
      drugName
      endTime
      followupDate
      groupQueueId
      inProgram
      locationName
      patientFirstName
      patientId
      patientLastName
      priority
      referralId
      source
      targetTime
      workStatus
    }
  }
`;
// startTime
// work

export const getWorkItemsByAgent = /* GraphQL */ `
  query GetWorkItemsByAgent($agentId: ID!, $workQueue: GroupQueue) {
    getWorkItemsByAgent(agentId: $agentId, workQueue: $workQueue) {
      description
      patientFirstName
      patientId
      patientLastName
      workItems {
        work
        workStatus
        attachedData
        clinicianReviewStatus
        createdAt
        createdBy
        description
        documentURI
        drugName
        id
        inProgram
        locationName
        parentWorkItemId
        patientFirstName
        patientId
        patientLastName
        payer
        prescriberFirstName
        prescriberLastName
        priority
        referralId
        source
        startTime
        state
        targetTime
        endTime
        followupDate
        groupQueueId
        agentFirstName
        agentLastName
        assignedTo
        caseId
        previousWorkStatus
      }
    }
  }
`;
export const getWorkItemsAssignedToAgent = /* GraphQL */ `
  query getWorkItemsAssignedToAgent($agentId: ID!) {
    getWorkItemsAssignedToAgent(agentId: $agentId) {
      work
      workStatus
      attachedData
      clinicianReviewStatus
      createdAt
      createdBy
      description
      documentURI
      drugName
      id
      inProgram
      locationName
      parentWorkItemId
      patientFirstName
      patientId
      patientLastName
      payer
      prescriberFirstName
      prescriberLastName
      previousWorkStatus
      priority
      referralId
      source
      startTime
      state
      targetTime
      endTime
      followupDate
      groupQueueId
      agentFirstName
      agentLastName
      assignedTo
      caseId
    }
  }
`;

export const getWorkItem = /* GraphQL */ `
  query GetWorkItem($workItemId: ID!) {
    getWorkItem(id: $workItemId) {
      assignedTo
      attachedData
      caseId
      createdAt
      createdBy
      currentStep
      description
      endTime
      followupDate
      groupQueueId
      id
      patientId
      priority
      processId
      referralId
      startTime
      targetTime
      updatedAt
      work
      workStatus
      previousWorkStatus
      workHistory {
        afterWorkStatus
        agentId
        calledOperation
        endTime
        lastWorkStatus
        notes
        reasonsForStatusChange
        recordTime
        startTime
        callAttempted
      }
    }
  }
`;
export const getPatientLocker = /* GraphQL */ `
  query GetPatientLocker($caseId: ID!) {
    getPatientLocker(caseId: $caseId) {
      caseId
      lockedAt
      lockedBy
    }
  }
`;

export const getPayersByInsurerName = /* GraphQL */ `
  query GetPayersByInsurerName(
    $insurerName: String!
    $planName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPayersByInsurerName(
      insurerName: $insurerName
      planName: $planName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        planName
        insurerId
        insurerName
        tradingPatnerId
        providerPhone
        electronicPayerId
        timelyFillingINN
        timelyFillingOON
        externalId
        createdAt
        updatedAt
        claimAddress {
          city
          state
          streetName
          zip
        }
      }
      nextToken
    }
  }
`;

// export const listGroups = /* GraphQL */ `
//   query ListGroups(
//     $taxId: ID
//     $filter: ModelGroupFilterInput
//     $limit: Int
//     $nextToken: String
//     $sortDirection: ModelSortDirection
//   ) {
//       taxId: $taxId
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//       sortDirection: $sortDirection
//     ) {
//       listGroups {
//         nextToken
//         items {
//           faxNumber
//           locations {
//             items {
//               groupId
//               locationNPI
//               locationName
//               state
//             }
//           }
//           name
//           phoneNumber
//           taxId
//         }
//       }
//     }
//   }
// `;
// export const listGroups = /* GraphQL */ `
//   query ListGroups {
//     listGroups {
//       items {
//         faxNumber
//         locations {
//           items {
//             groupId
//             locationNPI
//             locationName
//             state
//           }
//         }
//         name
//         phoneNumber
//         taxId
//       }
//     }
//   }
// `;

export const listGroupAICs = /* GraphQL */ `
  query ListGroupAICs {
    listGroupAICs(limit: 10000) {
      items {
        id
        name
        taxId
        state
        billingAddress {
          city
          state
          streetName
          county
          zip
        }
        phoneNumber
        faxNumber
        createdAt
        updatedAt
      }
    }
  }
`;

export const listLocationAICs = /* GraphQL */ `
  query ListLocationAICs {
    listLocationAICs(limit: 10000) {
      items {
        status
        id
        chairs
        locationName
        address {
          city
          state
          streetName
          county
          zip
        }
        state
        county
        notes {
          date
          agentName
          clinicalNote
        }
        billingAddress {
          city
          state
          streetName
          county
          zip
        }
        # chairs {
        #   id
        #   locationId
        #   name
        #   description
        #   timeZone
        #   officeHours {
        #     open
        #     close
        #     weekDay
        #   }
        #   holidays
        #   specialTimeSlots {
        #     open
        #     close
        #     weekDay
        #   }
        # }
        createdAt
        updatedAt
        timeZone
      }
    }
  }
`;

export const listProviderAICs = /* GraphQL */ `
  query ListProviderAICs {
    listProviderAICs(filter: { status: { eq: "ACTIVE" } }, limit: 10000) {
      items {
        providerNPI
        lastName
        firstName
        middleName
        # suffix
        cignaId
        unitedId
        aetnaId
        # sex
        type
      }
    }
  }
`;

export const listProviders = /* GraphQL */ `
  query ListProviders {
    listProviders {
      items {
        providerNPI
        lastName
        firstName
        # sex
        type
      }
    }
  }
`;

export const getWorkItemsFromScheduleQueue = /* GraphQL */ `
  query GetWorkItemsFromScheduleQueue(
    $agentId: ID!
    $workQueue: GroupQueue
    $period: DateRange!
  ) {
    getWorkItemsFromScheduleQueue(
      agentId: $agentId
      workQueue: $workQueue
      period: $period
    ) {
      dateAdded
      patientFirstName
      patientLastName
      orderName
      orderType
      drugType
      status
      priority
      patientId
      inProgram
      followupDate
      medicare
      planName
      insurerName
      locationAndProviders {
        groupId
        locationId
        providerId
        locationName
        providerFirstName
        providerLastName
      }
      freeDrug
      referralId
      id
    }
  }
`;

export const getWorkItemsFromNursingQueue = /* GraphQL */ `
  query GetWorkItemsFromNursingQueue(
    $agentId: ID!
    $locationId: String!
    $period: DateRange!
  ) {
    getWorkItemsFromNursingQueue(
      agentId: $agentId
      locationId: $locationId
      period: $period
    ) {
      message
      workItems {
        adminStage {
          adminSequenceNumber
          calcDosage
          unitOfMeas
          administer
        }
        locationName
        id
        adminSequenceNumber
        chairId
        chairName
        coPay
        dateAdded
        dob
        locationId
        orderName
        orderStatus
        patientFirstName
        patientId
        patientLastName
        providerFirstName
        providerLastName
        reason
        route
        referralId
        scheduleEventId
        scheduledEndTime
        scheduledStartTime
        status
      }
    }
  }
`;

export const getChairStateByLocationId = /* GraphQL */ `
  query GetChairStateByLocationId($locationId: String!) {
    getChairStateByLocationId(locationId: $locationId) {
      statusCode
      message
      chairStates {
        nursingProcessId
        chairId
        checkInTime
        patientLastName
        patientFirstName
        orderName
        orderType
        occupied
        status
      }
    }
  }
`;

export const getScheduleEventsByLocationId = /* GraphQL */ `
  query GetScheduleEventsByLocationId(
    $locationId: String!
    $period: DateRange!
  ) {
    getScheduleEventsByLocationId(locationId: $locationId, period: $period) {
      events {
        adminStage {
          adminSequenceNumber
          administer
          approvedDosage
          calcDosage
          dosageDateTimeFrameEvery
          dosageDateTimeFrameFor
          dosageDayRange
          dosageEvery
          dosageFor
          dosageFrequencyType
          dose
          drugName
          maxOfTreatments
          otherDosage
          route
          unitOfMeas
        }
        adminSequenceNumber
        agentFirstName
        agentId
        agentLastName
        chairId
        createdAt
        createdBy
        dob
        drugName
        endTime
        endTimeZone
        eventType
        id
        isNewPatient
        locationId
        locationName
        notes
        nursingProcessId
        orderName
        patientFirstName
        patientId
        patientLastName
        patientLatestWeight
        patientStatus
        providerFirstName
        providerId
        providerLastName
        referralId
        drugName
        #resources
        startTime
        startTimeZone
        status
        title
      }
      message
      statusCode
    }
  }
`;

// [MM] custom query for meta data
export const getScheduleEventMetaData = /* GraphQL */ `
  # query GetScheduleEventMetaData($patientId: ID!, $patientIdS: String!, $providerNPI: ID!) {
  query GetScheduleEventMetaData($patientId: ID!, $providerNPI: ID!) {
    getPatientBucket(patientId: $patientId) {
      patientId
      patientFirstName
      patientLastName
      dob
      homePhoneNumber
      patientProfile {
        insuranceInfo {
          isPatientInsured
          primary {
            insurerName
            planName
            insurerId
            lastName
            firstName
            groupId
          }
        }
      }
      referral {
        drugReferrals {
          referralType
          originalReceivedDate
          drugName
          referralOrder {
            administrations {
              adminSequenceNumber
              drugName
              maxOfTreatments
              route
              unitOfMeas
              approvedDosage
              calcDosage
              administer
              dosageFrequencyType
              dosageDayRange
              dosageEvery
              dosageDateTimeFrameEvery
              dosageFor
              dosageDateTimeFrameFor
              dose
            }
            orderName
            orderingProvider
            notes
          }
          drugId
          referralId
        }
      }
    }
    getProviderAIC(providerNPI: $providerNPI) {
      firstName
      lastName
      providerNPI
    }
    # >>> HERE <<<
  }
`;

// getTreatmentHistoryByPatient(
//   patientId: $patientIdS
// ) {
//     treatments {
//       referralOrder {
//         orderName
//         notes
//         orderDate
//       }
//       infusionDetail {
//         scheduleEventId
//         id
//         patientId
//         updatedAt
//         reasonForCancellation
//       }
//     }
//   }

export const getInfusionHeaderDetails = /* GraphQL */ `
  query GetInfusionHeaderDetails($locationId: ID!, $providerNPI: ID!) {
    getLocationAIC(id: $locationId) {
      id
      locationName
      state
      timeZone
      address {
        zip
      }
    }
    getProviderAIC(providerNPI: $providerNPI) {
      providerNPI
      firstName
      lastName
      # sex
      type
    }
  }
`;

export const getLocationAic = /* GraphQL */ `
  query GetLocationAic($id: ID!) {
    getLocationAIC(id: $id) {
      state
      locationName
      address {
        city
        zip
      }
      timeZone
    }
  }
`;

export const getProviderAIC = /* GraphQL */ `
  query GetProviderAIC($providerNPI: ID!) {
    getProviderAIC(providerNPI: $providerNPI) {
      firstName
      lastName
      middleName
      providerNPI
      sex
      type
      unitedId
      updatedAt
      aetnaId
      cignaId
      createdAt
    }
  }
`;

// export const getCaseByPatientId = /* GraphQL */ `
//   query MyQuery {
//     getCaseByPatientId($patientId: ID) {
//       caseDetail {
//         agentFirstName
//         GetCaseResp
//         agentId
//         agentLastName
//         alertLevels {
//           bi
//           intake
//           pa
//           updateDate
//           updatedBy
//         }
//         caseId
//         caseStatus
//         caseType
//         currentAssignedAgentId
//         description
//         documentURI
//         patientId
//         statusDetails {
//           isBICompleted
//           isIntakeCompleted
//           isPACompleted
//           updateDate
//         }
//         statusOfStages {
//           endTime
//           insuranceKey
//           referralId
//           stage
//           startTime
//           status
//         }
//       }
//       message
//       statusCode
//     }
//   }
// `;

export const getCaseByPatientId = /* GraphQL */ `
  query GetCaseByPatientId($patientId: ID!) {
    getCaseByPatientId(patientId: $patientId) {
      statusCode
      message
      caseDetail {
        caseId
        caseStatus
        currentAssignedAgentId
        description
        patientId
        agentId
        documentURI
        caseType
        agentLastName
        agentFirstName
        statusOfStages {
          endTime
          insuranceKey
          referralId
          stage
          startTime
          status
        }
        statusDetails {
          isBICompleted
          isIntakeCompleted
          isPACompleted
          updateDate
        }
        alertLevels {
          bi
          intake
          pa
          updateDate
          updatedBy
        }
      }
    }
  }
`;

export const getNursingProcess = /* GraphQL */ `
  query GetNursingProcess($id: ID!) {
    getNursingProcess(id: $id) {
      id
      chairId
      claim {
        claimDetail
      }
      claimId
      claimStatus
      createdAt
      currentStep
      faxRequired
      endTime
      # endTimestamp
      locationId
      notes
      notesComplete
      orderName
      patientFirstName
      patientId
      patientLastName
      providerId
      referralId
      scheduleEventId
      startTime
      # startTimestamp
      startedBy
      status
      stepAdministration {
        vitals {
          recordNumber
          enteredAt
          temperature
          bloodPressure
          heartRate
          R
          SP02
          initials
        }
        imDrugs {
          recordNumber
          time
          location
          amount
          unitOfAmount
          visualNotes
          # temperature
          # S
          # D
          # hr
          # R
          # SPO2
          initials
        }
        ivDrugs {
          recordNumber
          time
          event
          rate
          unitOfRate
          visualNotes
          initials
          totalInfusionInSec
        }
        otherIVDrugs {
          recordNumber
          time
          event
          rate
          unitOfRate
          visualNotes
          initials
          totalInfusionInSec
        }
        administrationComplete
      }
      stepAssessment {
        allergies {
          recordNumber
          allergen
          reaction
          entered
        }
        patientWeights {
          recordNumber
          weightLB
          weight
          changeFromLastKnown
          lastKnown
          entered
        }
        vitals {
          recordNumber
          enteredAt
          temperature
          bloodPressure
          heartRate
          R
          SP02
          initials
        }
        executedBy
        lastUpdatedTime
        noAssessmentToday
        questionnaire
      }
      stepCheckIn {
        checkInPatient
        verifiedDoB
        agentId
      }
      stepCloseTreatment {
        departureVital {
          recordNumber
          enteredAt
          temperature
          bloodPressure
          heartRate
          R
          SP02
          initials
        }
        closeTreatmentNote
        password
        signature
        departureTime
      }
      stepPreTreatment {
        lineFlush {
          recordNumber
          ivAccess
          flushType
          executedBy
          flushTime
        }
        picc {
          recordNumber
          portLocal
          lumens
          datePlaced
          armCircle
          unitOfArmCircle
          externalLength
          unitOfExternalLength
          bloodReturned
          flushed
          dressingChangedDate
          initials
          time
        }
        piv {
          recordNumber
          status
          attempt
          time
          catheter
          location
          entered
          ivDiscontinuedTime
          ivInsertion
          vein
          initials
        }
        port {
          recordNumber
          portLocal
          needleLength
          unitOfNeedleLength
          needleSize
          accessDate
          bloodReturned
          flushed
          deaccessedPort
          details
          recordTime
          initials
        }
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
          initials
        }
        executedBy
        lastUpdatedTime
        # noAssessmentToday
        preTreatmentCompleted
      }
      stepPreparation {
        diluent {
          recordNumber
          diluent
          quantity
          lot
          expiration
          inventoryId
        }
        drugs {
          recordNumber
          ndc
          vial
          strength
          uom
          route
          quantity
          lot
          expiration
          adjustedDosage
          calcDosage
          selectedQuantity
          wastage
          inventoryId
        }
        reconstitutedIn {
          recordNumber
          fluid
          quantity
          lot
          expiration
          inventoryId
        }
        noMedsAdministrated
        notes
        preparationComplete
      }
      stepReview {
        addendumOrderFilePath
        executedBy
        lastUpdatedTime
        orderApproved
        patientConsentReceived
      }
      updatedAt
    }
  }
`;

export const listPayers = /* GraphQL */ `
  query ListPayers($nextToken: String, $limit: Int) {
    listPayers(nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        id
        insurerId
        insurerName
        planName
        providerPhone
        timelyFillingINN
        timelyFillingOON
        tradingPatnerId
        externalId
        electronicPayerId
        createdAt
        claimAddress {
          city
          county
          state
          streetName
          zip
        }
      }
    }
  }
`;

export const getTreatmentHistoryByPatientNew = /* GraphQL */ `
  query GetTreatmentHistoryByPatient($patientId: String!, $period: DateRange) {
    getTreatmentHistoryByPatient(patientId: $patientId, period: $period) {
      patient {
        dob
        gender
        patientFirstName
        patientLastName
      }
      statusCode
      treatments {
        referralId
        referralUUID
        appoinmentTime
        arrivalTime
        departTime
        infusionDetail {
          stepLabDraw {
            accessPoint
          }
          chairId
          claim {
            claimDetail
            claimedAt
          }
          claimId
          createdAt
          currentStep
          endTime
          id
          locationId
          notes
          notesAboutCancellation
          notesComplete
          orderName
          orderType
          patientFirstName
          patientId
          patientLastName
          providerId
          reasonForCancellation
          referralId
          scheduleEventId
          startTime
          startedBy
          status
          stepAdministration {
            administrationComplete
            imDrugs {
              amount
              initials
              location
              recordNumber
              time
              unitOfAmount
              visualNotes
            }
            ivDrugs {
              event
              initials
              rate
              recordNumber
              time
              totalInfusionInSec
              unitOfRate
              visualNotes
            }
            otherIVDrugs {
              event
              initials
              rate
              recordNumber
              time
              totalInfusionInSec
              unitOfRate
              visualNotes
            }
            vitals {
              R
              SP02
              bloodPressure
              enteredAt
              heartRate
              initials
              recordNumber
              temperature
            }
          }
          stepAssessment {
            allergies {
              allergen
              entered
              reaction
              recordNumber
            }
            executedBy
            lastUpdatedTime
            noAssessmentToday
            patientWeights {
              changeFromLastKnown
              entered
              lastKnown
              recordNumber
              weightLB
              weight
            }
            questionnaire
            vitals {
              R
              SP02
              bloodPressure
              enteredAt
              heartRate
              initials
              recordNumber
              temperature
            }
          }
          stepCheckIn {
            agentId
            checkInPatient
            verifiedDoB
          }
          stepCloseTreatment {
            closeTreatmentNote
            departureTime
            departureVital {
              R
              SP02
              bloodPressure
              enteredAt
              heartRate
              initials
              recordNumber
              temperature
            }
            password
            signature
          }
          stepPreTreatment {
            executedBy
            lastUpdatedTime
            lineFlush {
              executedBy
              flushTime
              flushType
              ivAccess
              recordNumber
            }
            # noAssessmentToday
            picc {
              armCircle
              bloodReturned
              datePlaced
              dressingChangedDate
              externalLength
              flushed
              initials
              lumens
              portLocal
              recordNumber
              time
              unitOfArmCircle
              unitOfExternalLength
            }
            piv {
              attempt
              catheter
              entered
              initials
              ivDiscontinuedTime
              ivInsertion
              location
              recordNumber
              status
              time
              vein
            }
            port {
              accessDate
              bloodReturned
              deaccessedPort
              details
              flushed
              initials
              needleLength
              needleSize
              portLocal
              recordNumber
              recordTime
              unitOfNeedleLength
            }
            preMedications {
              administeredLocation
              dosage
              dosing
              expiration
              initials
              lot
              medication
              recordNumber
              time
              unitOfDosage
            }
            preTreatmentCompleted
          }
          stepPreparation {
            diluent {
              diluent
              lot
              quantity
              expiration
              recordNumber
            }
            drugs {
              expiration
              lot
              ndc
              quantity
              recordNumber
              route
              strength
              uom
              vial
              adjustedDosage
              calcDosage
              selectedQuantity
              wastage
            }
            noMedsAdministrated
            notes
            preparationComplete
            reconstitutedIn {
              expiration
              fluid
              lot
              quantity
              recordNumber
            }
          }
          stepReview {
            addendumOrderFilePath
            executedBy
            lastUpdatedTime
            orderApproved
            patientConsentReceived
          }
          updatedAt
        }
        locationId
        locationName
        prescriberFax
        prescriberFirstName
        prescriberLastName
        prescriberNPI
        prescriberPhoneNumber
        providerFirstName
        providerLastName
        providerNPI
        referralOrder {
          administrations {
            adminSequenceNumber
            administer
            approvedDosage
            calcDosage
            dosageDateTimeFrameEvery
            dosageDateTimeFrameFor
            dosageDayRange
            dosageEvery
            dosageFor
            dosageFrequencyType
            dose
            drugName
            maxOfTreatments
            otherDosage
            route
            unitOfMeas
          }
          medicationType
          orderExpires
          orderDate
          notes
          orderName
          orderType
          orderingProvider
          patientWeight
          unitOfWeight
          preMedications {
            administer
            approvedDosage
            drugName
            isPreMed
            maxOfTreatments
            route
            unitOfMeas
          }
          primaryDX {
            description
            diagnosedBy
            primaryDiagnosis
          }
          otherDX {
            primaryDiagnosis
            description
            diagnosedBy
          }
        }
        timeInChair
      }
    }
  }
`;

export const getShipmentsByLocation = /* GraphQL */ `
  query GetShipmentsByLocation($locationId: String!, $period: DateRange!) {
    getShipmentsByLocation(locationId: $locationId, period: $period) {
      statusCode
      message
      shipments {
        id
        shippedDate
        expectedDeliveryDate
        trackingNumber
        shippedBy
        shippingCompany
        shippingToLocationId
        shippingStatus
        productOrderIds
        items {
          expirationDate
          fromInventoryId
          lotNumber
          ndcNumber
          price
          productName
          quantity
          vendor
        }
      }
    }
  }
`;

export const getTreatmentHistoryByPatient2 = /* GraphQL */ `
  query GetTreatmentHistoryByPatient($patientId: String) {
    getTreatmentHistoryByPatient(patientId: $patientId, limit: 10000) {
      items {
        orderName
        status
        scheduleEventId
        referralId
        reasonForCancellation
        providerId
        patientLastName
        patientId
        patientFirstName
        notesComplete
        notesAboutCancellation
        notes
        locationId
        currentStep
        chairId
        startTime
        endTime
        id
        stepAdministration {
          vitals {
            recordNumber
            enteredAt
            temperature
            bloodPressure
            heartRate
            R
            SP02
            initials
          }
          imDrugs {
            recordNumber
            time
            location
            amount
            unitOfAmount
            visualNotes
            # temperature
            # S
            # D
            # hr
            # R
            # SPO2
            initials
          }
          ivDrugs {
            recordNumber
            time
            event
            rate
            unitOfRate
            visualNotes
            initials
            totalInfusionInSec
          }
          otherIVDrugs {
            recordNumber
            time
            event
            rate
            unitOfRate
            visualNotes
            initials
            totalInfusionInSec
          }
          administrationComplete
        }
        stepAssessment {
          allergies {
            recordNumber
            allergen
            reaction
            entered
          }
          patientWeights {
            recordNumber
            weightLB
            weight
            changeFromLastKnown
            lastKnown
            entered
          }
          vitals {
            recordNumber
            enteredAt
            temperature
            bloodPressure
            heartRate
            R
            SP02
            initials
          }
          noAssessmentToday
          lastUpdatedTime
          executedBy
          questionnaire
        }
        stepCheckIn {
          checkInPatient
          verifiedDoB
          agentId
        }
        stepCloseTreatment {
          departureVital {
            recordNumber
            enteredAt
            temperature
            bloodPressure
            heartRate
            R
            SP02
            initials
          }
          signature
          password
          departureTime
          closeTreatmentNote
        }
        stepPreTreatment {
          lineFlush {
            recordNumber
            ivAccess
            flushType
            executedBy
            flushTime
          }
          picc {
            recordNumber
            portLocal
            lumens
            datePlaced
            armCircle
            unitOfArmCircle
            externalLength
            unitOfExternalLength
            bloodReturned
            flushed
            dressingChangedDate
            initials
            time
          }
          piv {
            recordNumber
            status
            attempt
            time
            catheter
            location
            entered
            ivDiscontinuedTime
            ivInsertion
            vein
            initials
          }
          port {
            recordNumber
            portLocal
            needleLength
            unitOfNeedleLength
            needleSize
            accessDate
            bloodReturned
            flushed
            deaccessedPort
            details
            recordTime
            initials
          }
          preMedications {
            recordNumber
            time
            medication
            dosing
            administeredLocation
            dosage
            unitOfDosage
            lot
            expiration
            initials
          }
          preTreatmentCompleted
          # noAssessmentToday
          lastUpdatedTime
          executedBy
        }
        stepPreparation {
          diluent {
            recordNumber
            diluent
            quantity
            lot
            expiration
          }
          drugs {
            recordNumber
            ndc
            vial
            strength
            uom
            route
            quantity
            lot
            expiration
          }
          reconstitutedIn {
            recordNumber
            fluid
            quantity
            lot
            expiration
          }
          noMedsAdministrated
          notes
          preparationComplete
        }
        stepReview {
          addendumOrderFilePath
          executedBy
          lastUpdatedTime
          orderApproved
          patientConsentReceived
        }
      }
    }
  }
`;

export const listProductInventories = /* GraphQL */ `
  query listProductInventories {
    listProductInventories(limit: 10000) {
      items {
        currentQuantity
        expirationDate
        freeDrug
        id
        initialQuantity
        locationId
        lotNumber
        productId
        price
        productName
        receivedBy
        status
        strengthPerVial
        unitOfMeasure
        updateHistory {
          notes
          updatedAt
          updatedBy
        }
        updatedBy
        vendor
      }
    }
  }
`;

export const listProductOrders = /* GraphQL */ `
  query ListProductOrders {
    listProductOrders(limit: 10000) {
      items {
        dose
        id
        initialQuantity
        locationId
        orderDate
        orderQuantity
        orderStatus
        orderedBy
        productId
        productName
        unitOfMeasure
        updatedAt
        updatedBy
      }
    }
  }
`;

export const listProductOrdersByLocation = /* GraphQL */ `
  query ListProductOrdersByLocation($locationId: ID!) {
    listProductOrders(
      limit: 10000
      filter: { locationId: { eq: $locationId } }
    ) {
      items {
        dose
        id
        initialQuantity
        locationId
        orderDate
        orderQuantity
        orderStatus
        orderedBy
        productId
        productName
        unitOfMeasure
        updatedAt
        updatedBy
      }
    }
  }
`;

export const getScheduledEventsForOrder = /* GraphQL */ `
  query GetScheduledEventsForOrder {
    getScheduledEventsForOrder {
      scheduledEvents {
        appointmentDate
        appointmentStatus
        locationId
        locationName
        patientId
        patientLastName
        patientFirstName
        referralId
        orderName
        calcDose
        unitOfMeasure
        freeDrug
        orderStatus
        eventId
      }
      statusCode
    }
  }
`;

export const getScheduledEventsForOrderByDate = /* GraphQL */ `
  query GetScheduledEventsForOrder(
    $endDate: AWSDateTime!
    $startDate: AWSDateTime!
  ) {
    getScheduledEventsForOrder(
      period: { endDate: $endDate, startDate: $startDate }
    ) {
      scheduledEvents {
        appointmentDate
        appointmentStatus
        locationId
        locationName
        patientId
        patientLastName
        patientFirstName
        referralId
        orderName
        calcDose
        unitOfMeasure
        freeDrug
        orderStatus
        eventId
      }
      statusCode
    }
  }
`;

// same as `getOrderReportsByDateRange`, but returns for 2 weeks as default
export const getOrderReports = /* GraphQL */ `
  query GetOrderReportsByDateRange($agentId: String!) {
    getOrderReportsByDateRange(agentId: $agentId) {
      message
      reports {
        orders {
          dose
          id
          initialQuantity
          locationId
          locationName
          orderDate
          orderQuantity
          orderStatus
          orderedBy
          productName
          productId
          unitOfMeasure
          updatedAt
          updatedBy
        }
        reportId
        reportedAt
      }
      statusCode
    }
  }
`;

// allows to set a custom period for the history fetch
export const getOrderReportsByDateRange = /* GraphQL */ `
  query GetOrderReportsByDateRange(
    $agentId: String!
    $endDate: AWSDateTime!
    $startDate: AWSDateTime!
  ) {
    getOrderReportsByDateRange(
      agentId: $agentId
      period: { endDate: $endDate, startDate: $startDate }
    ) {
      message
      reports {
        orders {
          dose
          id
          initialQuantity
          locationId
          locationName
          orderDate
          orderQuantity
          orderStatus
          orderedBy
          productName
          productId
          unitOfMeasure
          updatedAt
          updatedBy
        }
        reportId
        reportedAt
      }
      statusCode
    }
  }
`;

export const getInventoryByNDC = /* GraphQL */ `
  query GetInventoryByNDC($agentId: String!, $ndcList: [String!]!) {
    getInventoryByNDC(agentId: $agentId, ndcList: $ndcList) {
      inventory {
        ndc
        quantity
        items {
          currentQuantity
          expirationDate
          id
          locationId
          lotNumber
          price
          productId
          productName
          strengthPerVial
          unitOfMeasure
          vendor
        }
      }
      message
      statusCode
    }
  }
`;
export const getInventoryByLocation = /* GraphQL */ `
  query GetInventoryByLocation($locationId: ID!, $nextToken: String) {
    getInventoryByLocation(
      locationId: $locationId
      nextToken: $nextToken
      limit: 10000
    ) {
      items {
        currentQuantity
        expirationDate
        freeDrug
        id
        initialQuantity
        locationId
        lotNumber
        price
        productId
        productName
        receivedBy
        status
        strengthPerVial
        unitOfMeasure
        updatedBy
        receivedTimestamp
        vendor
        productInfo {
          isReconstituted
        }
        notes
        serial
        updateHistory {
          afterQuantity
          beforeQuantity
          notes
          reason
          updatedAt
          updatedBy
        }
      }
      nextToken
    }
  }
`;

export const getReferral = /* GraphQL */ `
  query GetReferral($patientId: ID!, $referralId: String!) {
    getReferral(patientId: $patientId, referralId: $referralId) {
      statusCode
      message
      referralDetail {
        rxOrderFile
        referralOrder {
          orderName
          orderType
          orderingProvider
          orderDate
          orderExpires
          primaryDX {
            primaryDiagnosis
            description
            diagnosedBy
          }
          otherDX {
            description
            diagnosedBy
            primaryDiagnosis
          }
          medicationType
          administrations {
            adminSequenceNumber
            drugName
            maxOfTreatments
            route
            unitOfMeas
            approvedDosage
            calcDosage
            administer
            dosageFrequencyType
            dosageDayRange
            dosageEvery
            dosageDateTimeFrameEvery
            dosageFor
            dosageDateTimeFrameFor
            dose
          }
          preMedications {
            drugName
            maxOfTreatments
            route
            unitOfMeas
            approvedDosage
            administer
            isPreMed
          }
          notes
        }
        referralId
        drugId
        drugName
        prescriberId
        drugType
        patientHasStartedTherapy
        noOfTreatments
        firstTreatmentDate
        lastTreatmentDate
        inventorySource
        specialPharmName
        specialPharmPhoneNumber
        referralApproved
        scheduling
        archiveOrder
        reasonForArchiving
        isCompleted

        approvedDosage
      }
    }
  }
`;

export const getLocationsAndProvidersByGroupId = /* GraphQL */ `
  query GetLocationsAndProvidersByGroupId(
    $groupId: String!
    $insurerId: String
  ) {
    getLocationsAndProvidersByGroupId(
      groupId: $groupId
      insurerId: $insurerId
    ) {
      id
      locationNPI
      locationName
      insured
      providers {
        firstName
        lastName
        providerNPI
        type
      }
      state
    }
  }
`;

export const getBillingHistoryByPatientId = /* GraphQL */ `
  query GetBillingHistoryByPatient($patientId: String!, $nextToken: String) {
    getBillingHistoryByPatient(patientId: $patientId, nextToken: $nextToken) {
      items {
        id
        billingStatus
        locationId
        locationName
        r2Status
        serviceDate
        serviceDateTime
        orderName
        nursingProcessId
        treatmentHistory
        billingDetail {
          procedureCodes
          inventorySource
          insuranceInfo {
            primary {
              groupId
              insuranceExpireDate
              insurerName
              payerId
              planName
              policyId
              relationship
              rxGroupNumber
              state
              verifiedInsurance
              rxPlanName
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getBillingHistoryInfo = /* GraphQL */ `
  query GetBillingHistoryInfo($id: ID!) {
    getBillingHistoryInfo(id: $id) {
      billingStatus
      id
      locationId
      locationName
      nursingProcessId
      orderName
      patientId
      r2Status
      serviceDate
      serviceDateTime
      billingDetail {
        authorizationInfo {
          authStatus
          callCompletionTime
          denialReason
          isCompleted
          mdoContacted
          referralId
          requestHistory {
            adminCodes {
              code
              units
            }
            billings {
              groupId
              inNetworkNPI
              inNetworkTIN
              locationId
              outOfNetworkNPI
              outOfNetworkTIN
              providerId
            }
            jCodes {
              code
              units
            }
            personSpokeWith
            phoneNumber
            submittedDate
            callReference
            followUpDate
            insuranceCompanyName
          }
          stat
          welcomeCallCompleted
          insuranceKey
          approvalInfo {
            isSiteSpecific
            numberOfApprovedUnits
            numberOfApprovedVisits
            priorAuthNumber
            serviceFrom
            serviceTo
          }
        }
        benefitCheckingInfo {
          checkings {
            groupId
            insuranceKey
            isCompleted
            paRequired
            priorAuthorization {
              adminCode1
              adminCode2
              adminCode3
              jCode
              paPhone
              paSite
              submitMethod
            }
            billingNPIForOutOfNetwork
            billingNPINumber
            billingOverrideReason
            billingTaxId
            billingTaxIdForOutOfNetwork
            callCompletionTime
            callReferenceNumber
            claims {
              claimAddress {
                city
                county
                state
                streetName
                zip
              }
              results
              timelyFilling
            }
            policy {
              coPay
              coveragePercentage
              dedType
              deductibleInfo {
                deductibleType
                metDeductible {
                  amount
                  resetDate
                }
                metOOP {
                  amount
                  resetDate
                }
                networkStatus
                totalDeductibleAmount
                totalOOPAmount
              }
              deductibleNotes
              effectiveDate
              loa
              networkStatus
              oopMax {
                deductibleType
                metDeductible {
                  amount
                  resetDate
                }
                metOOP {
                  amount
                  resetDate
                }
                networkStatus
                totalDeductibleAmount
                totalOOPAmount
              }
              outOfNetworkBenefits
              payerPhoneNumber
              planName
              planType
              termDate
              verificationMethod
            }
            policyId
            predetermination {
              adminCode1
              adminCode2
              adminCode3
              jCode
              paPhone
              paSite
              submitMethod
            }
            predeterminationNeeded
            representativeName
            selectedBillingNPI
            selectedBillingTaxId
            selectedGroupId
            selectedLocationId
            selectedProviderId
            verifiedDate
          }
          callCompletionTime
          referralId
          welcomeCallCompleted
          welcomeCalls {
            agentId
            answered
          }
        }
        insuranceInfo {
          primary {
            groupId
            insurerId
            insurerName
            binNumber
            policyId
            planName
            coveredBy
            customerServicePhone
            email
            firstName
            hasPharmacyBenefits
            insuranceExpireDate
            lastName
            payerId
            pcnNumber
            pharmacyPhone
            relationship
            rxGroupNumber
            rxPlanName
            state
          }
          secondary {
            groupId
            insurerId
            insurerName
            binNumber
            policyId
            planName
            coveredBy
            customerServicePhone
            email
            firstName
            hasPharmacyBenefits
            insuranceExpireDate
            lastName
            payerId
            pcnNumber
            pharmacyPhone
            relationship
            rxGroupNumber
            rxPlanName
            state
          }
          tertiary {
            groupId
            insurerId
            insurerName
            binNumber
            policyId
            planName
            coveredBy
            customerServicePhone
            email
            firstName
            hasPharmacyBenefits
            insuranceExpireDate
            lastName
            payerId
            pcnNumber
            pharmacyPhone
            relationship
            rxGroupNumber
            rxPlanName
            state
          }
        }
        inventorySource
        procedureCodes
      }
      treatmentHistory
    }
  }
`;

export const getWorkItemsByPatient = /* GraphQL */ `
  query GetWorkItemsByPatient(
    $patientId: ID!
    $endDate: AWSDateTime!
    $startDate: AWSDateTime!
    $workType: String
  ) {
    getWorkItemsByPatient(
      patientId: $patientId
      period: { endDate: $endDate, startDate: $startDate }
      workType: $workType
    ) {
      agentFirstName
      agentLastName
      assignedTo
      attachedData
      caseId
      createdAt
      createdBy
      description
      documentURI
      drugName
      endTime
      followupDate
      groupQueueId
      id
      parentWorkItemId
      patientFirstName
      previousWorkStatus
      patientId
      patientLastName
      priority
      referralId
      source
      startTime
      targetTime
      work
      workStatus
    }
  }
`;
//       createdBy nulls in data cause exceptions

export const getScheduleEventsByPatient = /* GraphQL */ `
  query GetScheduleEventsByPatient($patientId: String!) {
    getScheduleEventsByPatient(patientId: $patientId) {
      events {
        adminSequenceNumber
        adminStage {
          adminSequenceNumber
          administer
          approvedDosage
          calcDosage
          dosageDateTimeFrameEvery
          dosageDateTimeFrameFor
          dosageDayRange
          dosageEvery
          dosageFor
          dosageFrequencyType
          dose
          drugName
          maxOfTreatments
          otherDosage
          route
          unitOfMeas
        }
        agentFirstName
        agentId
        agentLastName
        chairId
        createdAt
        createdBy
        dob
        endTime
        endTimeZone
        eventType
        id
        locationId
        locationName
        notes
        nursingProcessId
        orderName
        patientFirstName
        patientId
        patientLastName
        patientLatestWeight
        providerFirstName
        providerId
        providerLastName
        reason
        referralId
        referralUUID
        drugName
        resources
        startTime
        startTimeZone
        status
        title
      }
      message
      statusCode
    }
  }
`;

export const getProvidersByLocationId = /* GraphQL */ `
  query GetProvidersByLocationId($locationId: String!) {
    getProvidersByLocationId(locationId: $locationId) {
      providerNPI
      lastName
      firstName
      sex
      type
    }
  }
`;
export const listCodeInfos = /* GraphQL */ `
  query ListCodeInfos($code: String, $limit: Int, $nextToken: String) {
    listCodeInfos(code: $code, limit: $limit, nextToken: $nextToken) {
      items {
        code
        description
        createdAt
        updatedAt
        codeType
      }
      nextToken
    }
  }
`;

export const getLocationsWithActiveOrders = /* GraphQL */ `
  query GetLocationsWithActiveOrders {
    getLocationsWithActiveOrders {
      id
      locationName
      totalOrderQuantity
      state
    }
  }
`;

export const getCodeInfo = /* GraphQL */ `
  query GetCodeInfo($code: String!) {
    getCodeInfo(code: $code) {
      code
      description
      createdAt
      updatedAt
      codeType
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      adminSequenceNumber
      endTime
      endTimeZone
      startTime
      startTimeZone
      status
      title
    }
  }
`;

export const getLatestPriceOfDrugByNDC = /* GraphQL */ `
  query GetLatestPriceOfDrugByNDC($ndc: String!) {
    getLatestPriceOfDrugByNDC(ndc: $ndc) {
      statusCode
      receivedDate
      ndc
      message
      latestPrice
    }
  }
`;

export const getUsersByRole = /* GraphQL */ `
  query GetUsersByRole {
    getUsersByRole(role: SALES) {
      items {
        userId
        role
        lastName
        firstName
      }
    }
  }
`;

export const getSalesCommissionsByPrescriberId = /* GraphQL */ `
  query GetSalesCommissionsByPrescriberId($prescriberId: ID!) {
    getSalesCommissionsByPrescriberId(prescriberId: $prescriberId) {
      items {
        id
        userInfo {
          userId
          role
          lastName
          firstName
        }
      }
    }
  }
`;

export const getProductInfoByName = /* GraphQL */ `
  query GetProductInfoByName($productName: String!) {
    getProductInfoByName(productName: $productName) {
      items {
        jCode
        productName
        productId
      }
    }
  }
`;

export const getBillingHistoryByDates = /* GraphQL */ `
  query GetBillingHistoryByDates(
    $endDate: AWSDateTime!
    $startDate: AWSDateTime!
  ) {
    getBillingHistoryByDates(
      period: { endDate: $endDate, startDate: $startDate }
    ) {
      id
      billingStatus
      infusionStatus
      insurerName
      locationId
      locationName
      nursingProcessId
      orderName
      patientFirstName
      patientId
      patientLastName
      providerFirstName
      providerLastName
      r2Status
      serviceDate
    }
  }
`;

export const getAdminStageForScheduleEvent = /* GraphQL */ `
  query GetAdminStageForScheduleEvent(
    $patientId: String!
    $referralId: String!
    $appointmentDate: AWSDateTime!
    $eventToUpdate: String
  ) {
    getAdminStageForScheduleEvent(
      patientId: $patientId
      referralId: $referralId
      appointmentDate: $appointmentDate
      eventToUpdate: $eventToUpdate
    ) {
      adminStage {
        calcDosage
        approvedDosage
        adminSequenceNumber
        dosageDateTimeFrameEvery
        dosageDateTimeFrameFor
        dosageDayRange
        dosageEvery
        dosageFor
        dosageFrequencyType
        dose
        maxOfTreatments
        drugName
        otherDosage
        route
        unitOfMeas
      }
      targetInjectionInfo {
        dosingWindow
        targetDate
      }
      message
      statusCode
    }
  }
`;

export const listDiluentProducts = /* GraphQL */ `
  query ListProducts {
    listProducts(filter: { typeOfProduct: { eq: DILUENT } }, limit: 10000) {
      items {
        productName
        productId
        dosing
        frequency
        price
        route
        status
        strength
        unitOfMeas
        vendor
        scheduledAllotment
        packageType
      }
    }
  }
`;

export const listReconstitutedProducts = /* GraphQL */ `
  query ListProducts {
    listProducts(filter: { isReconstituted: { eq: true } }, limit: 10000) {
      items {
        productName
        productId
        dosing
        frequency
        price
        route
        status
        strength
        unitOfMeas
        vendor
        scheduledAllotment
        packageType
      }
    }
  }
`;

export const getPatientBucketBulletin = /* GraphQL */ `
  query GetPatientBucketBulletin($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      bulletinBoard
    }
  }
`;

export const GroupAIC = /* GraphQL */ `
  query GroupAIC($id: ID!) {
    getGroupAIC(id: $id) {
      name
    }
  }
`;

export const listInsurerAICs = /* GraphQL */ `
  query ListInsurerAICs {
    listInsurerAICs(limit: 10000) {
      nextToken
      items {
        taxId
        name
        insurerType
        id
        billingAddress {
          zip
          streetName
          state
          county
          city
        }
        createdAt
      }
    }
  }
`;

export const checkPatientForDeactivation = /* GraphQL */ `
  query CheckPatientForDeactivation($patientId: String!) {
    checkPatientForDeactivation(patientId: $patientId) {
      statusCode
      message
    }
  }
`;

export const checkReferralForDiscontinuation = /* GraphQL */ `
  query CheckReferralForDiscontinuation(
    $patientId: String!
    $referralId: String!
  ) {
    checkReferralForDiscontinuation(
      patientId: $patientId
      referralId: $referralId
    ) {
      message
      statusCode
    }
  }
`;

export const getNewPrescriberInfoByNPI = /* GraphQL */ `
  query GetNewPrescriberInfoByNPI($prescriberNPI: String!) {
    getNewPrescriberInfoByNPI(prescriberNPI: $prescriberNPI) {
      message
      statusCode
    }
  }
`;

export const listPatientSurveys = /* GraphQL */ `
  query listPatientSurveys($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      surveys {
        collectedAt
        collectedBy
        description
        survey
        typeOfSurvey
        version
      }
    }
  }
`;

export const getPatientHeaderDetailsForSurvey = /* GraphQL */ `
  query getPatientHeaderDetailsForSurvey($patientId: ID!) {
    getPatientBucket(patientId: $patientId) {
      dob
      patientFirstName
      patientId
      patientLastName
      patientProfile {
        weightInfo {
          changeFromLastKnown
          entered
          lastKnown
          recordNumber
          unitOfWeight
          weight
          weightLB
        }
        patientInfo {
          address {
            city
            state
          }
        }
      }
      referral {
        drugReferrals {
          referralType
          approvedDosage
          referralId
          drugName
          drugType
          drugId
          lastTreatmentDate
          referralOrder {
            primaryDX {
              description
              diagnosedBy
              primaryDiagnosis
            }
            otherDX {
              description
              diagnosedBy
              primaryDiagnosis
            }
            orderName
            orderType
            orderExpires
            orderDate
            notes
            medicationType
            unitOfWeight
          }
          rxOrderFile
        }
      }
    }
  }
`;

export const getAssessmentWeights = /* GraphQL */ `
  query GetNursingProcess($id: ID!) {
    getNursingProcess(id: $id) {
      stepAssessment {
        patientWeights {
          changeFromLastKnown
          entered
          lastKnown
          recordNumber
          unitOfWeight
          weight
          weightLB
        }
      }
    }
  }
`;

export const getProgramsByPatient = /* GraphQL */ `
  query GetProgramsByPatient($agentId: String!, $patientId: String!) {
    getProgramsByPatient(agentId: $agentId, patientId: $patientId) {
      message
      programs {
        approved
        id
        name
      }
      statusCode
    }
  }
`;

export const getCodesByCodeType = /* GraphQL */ `
  query getCodesByCodeType($codeType: String!) {
    getCodesByCodeType(codeType: $codeType) {
      items {
        codeName
        description
      }
    }
  }
`;
export const getPrescribersByLastName = /* GraphQL */ `
  query getPrescribersByLastName(
    $filter: ModelPrescriberInfoFilterInput
    $limit: Int
    $nextToken: String
    $prescriberLastName: String!
    $sortDirection: ModelSortDirection
  ) {
    getPrescribersByLastName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      prescriberLastName: $prescriberLastName
      sortDirection: $sortDirection
    ) {
      items {
        medicalSpecialty
        officeContactFirstName
        officeContactLastName
        siteInstitutionName
        taxIDNumber
        officeEmail
        officePhoneNumber
        officeFaxNumber
        prescriberFirstName
        prescriberMiddleName
        prescriberLastName
        HINNumber
        officeContactName
        NPINumber
        createdAt
        updatedAt
        officeAddresses {
          city
          state
          streetName
          county
          zip
          id
          phoneNumber
          faxNumber
          default
          officeContactName
          addressTitle
        }
        notes {
          date
          type
          note
          drugName
          labTest
          labExpiration
        }
      }
      nextToken
    }
  }
`;

export const getPatientWorkItems = /* GraphQL */ `
  query GetPatientWorkItems($patientId: String!) {
    getAllWorkItemsByPatient(patientId: $patientId, limit: 10000) {
      items {
        attachedData
        createdAt
        description
        id
        parentWorkItemId
        priority
        updatedAt
        workItemType
        workStatus
        work
        workHistory {
          afterWorkStatus
          agentId
          callAttempted
          calledOperation
          endTime
          lastWorkStatus
          notes
          reasonsForStatusChange
          recordTime
          startTime
        }
      }
    }
  }
`;
export const getSystemScheduledItemsByPatient = /* GraphQL */ `
  query GetSystemScheduledItemsByPatient(
    $patientId: String!
    $sortDirection: ModelSortDirection
  ) {
    getSystemScheduledItemsByPatient(
      patientId: $patientId
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        createdBy
        description
        id
        invokedTS
        itemStatus
        method
        parameters
        patientId
        recurring
        service
      }
    }
  }
`;
export const getNurseCalendarByLocation = /* GraphQL */ `
  query getNurseCalendarByLocation(
    $endDate: AWSDateTime!
    $startDate: AWSDateTime!
    $aicLocation: String!
  ) {
    getNurseCalendarByLocation(
      aicLocation: $aicLocation
      period: { endDate: $endDate, startDate: $startDate }
    ) {
      message
      results
      statusCode
    }
  }
`;
export const listSalesTerritories = /* GraphQL */ `
  query listSalesTerritories($limit: Int, $nextToken: String) {
    listSalesTerritories(limit: $limit, nextToken: $nextToken) {
      items {
        name
        id
        accountExecutiveName
      }
      nextToken
    }
  }
`;

export const checkIsNewPatient = /* GraphQL */ `
  query CheckIsNewPatient($patientId: ID!) {
    isNewPatient(patientId: $patientId) {
      message
      results
      statusCode
    }
  }
`;
