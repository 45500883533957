export default [
  {
    version: '4.5.4',
    date: '11/16/2024',
    notes: `    
### EMERGENCY RELEASE
- X-ref update to add a new provider to the system (OPTML-2263)
- Fix issue to capture the invalid appointments (OPTML-2245)
- Fix issue to update the correct rendering provider on treatment notes (OPTML-2271)
- Billing History Missing: Patient Data Not Appearing on Billing Tab for Multiple DOS(OPTML-2241)
- OptML Inventory Decrement Issue: Incorrect Handling of Multiple Item Quantities(OPTML-2254)

    `
  },
  {
    version: '4.5.3',
    date: '10/7/2024',
    notes: `    
### EMERGENCY RELEASE

- When infusion closes - initial view of TH doesn't have narrative notes (OPTML-2188)
`
  },
  {
    version: '4.5.2',
    date: '10/3/2024',
    notes: `    
### EMERGENCY RELEASE

- Fix Issue for scheduling a patient when the patient info data is null (OPTML-2023)
`
  },
  {
    version: '4.5.1',
    date: '10/1/2024',
    notes: `    
### EMERGENCY RELEASE

- Allow admin user to update TH nursing notes after infusion completed (OPTML-2149)
`
  },
  {
    version: '4.5.0',
    date: '9/28/2024',
    notes: `    
### FEATURES

- UUID ph 2 || Add linkage (therapyId) to all referral data (OPTML-2142, OPTML-2143, OPTML-2108, OPTML-2169)
- E&M Add 2 new document types (OPTML-2156)

### BUG FIXES

- Prescriber || Allow for bad describer data from Weinfuse patients (OPTML-2139)
`
  },
  {
    version: '4.4.5',
    date: '8/14/2024',
    notes: `    
### FEATURES

- Display referralId / referralUUID in Application (OPTML-2098, OPTML-2099, OPTML-2100, OPTML-2101)
- Update the error message text on the productAdmin file (OPTML-2049)
- Vendor Lists updates to Product and Site Inventory (OPTML-2110)

### BUG FIXES

- Scheduling work items: updated logic (OPTML-2004)
- Reschedule appointments with no Provider ID: (OPTML-2085, OPTML-2077)
- Pre Order Work Items Creating Incorrectly (OPTML-2090)
- Z-index: Inbound Fax Create Work Item (OPTML-2103)
`
  },
  {
    version: '4.4.4',
    date: '8/10/2024',
    notes: `    
### FEATURES

- Site Inventory: Product Bulk Adjustments (OPTML-1926, OPTML-1938)
- Site Inventory: Show History Log (OPTML-1927)
- Site Inventory: Allow user to see items with QTY=0 (OPTML-1940)
`
  },
  {
    version: '4.4.3',
    date: '8/6/2024',
    notes: `    
### EMERGENCY RELEASE

- Treatment Note Error for Patient 977280199 (OPTML-2018)
`
  },
  {
    version: '4.4.2',
    date: '8/3/2024',
    notes: `    
### FEATURES

- Update scheduling work item priority 5 to 6 (OPTML-1877) 
- Allow User to Create Appointments for Office Visit / Telehealth (OPTML-1983, OPTML-1984, OPTML-1995)
- Add New Cancel/Reschedule Appointment Reason for Telehealth (OPTML-1990)
- Add Telehealth Chair to Peoria(s), Kingman, Bullhead City (OPTML-1989, OPTML-1991)
`
  },
  {
    version: '4.4.1',
    date: '7/26/2024',
    notes: `    
### EMERGENCY RELEASE

- Production navigating to another tab after TH notes tab (OPTML-1974) 
- Patient Status: startDate is earlier than the date when the request is created (OPTML-1971)
- Patient Status not updating as expected for dates in the future (OPTML-1980)
`
  },
  {
    version: '4.4.0',
    date: '7/20/2024',
    notes: `    
### FEATURES

- Fishbowl Import File Updates (Serial Number) (OPTML-1837) 
- Add new statuses: Expedited/Stat (OPTML-1867) 
- Display Historical Patient Status Data (OPTML-1851, OPTML-1776)
- Add error checking to prevent bad data saving in PA/BI fields (OPTML-823) 
- Add Spinner to Get Inventory from Site Button (OPTML-1474) 
- Update fields in Data Admin: Add New Product Modal (OPTML-1716) 
- Add When2Work API to pull in Description (OPTML-1845) 
- Add new discontinuation reason codes to Disc. Survey (OPTML-1883)
- Treatment history notes: Display latest and historical updates (OPTML-1902)

### BUG FIXES

- Prevent users from checking in patient w/ out selecting a provider (OPTML-1895) 
- Check Current Referral Admin Delete Button Visibility (OPTML-1912) 
- Lab Draw Modal displaying incorrect data for "Nurse" field (OPTML-1921) 
- Add validation to prevent a user from archiving a referral when a patient is checked in (OPTML-1935) 
- Current Order: Admin Seq prevent a user from deleting Admin 1 before Admin 2 > keep in sequence (OPTML-1949) 
`
  },
  {
    version: '4.3.2',
    date: '6/29/2024',
    notes: `    
### FEATURES

- Display New Patient Status on Calendar Events (OPTML-1868)
- X-ref update/ master location table update: deactivate Tuscon NW, colorado Springs, other items (OPTML-1887)

### BUG FIXES

- Check Lab Draw Rendering Provider and Signature match on TH notes (OPTML-1909)
`
  },
  {
    version: '4.3.1',
    date: '6/26/2024',
    notes: `    
### EMERGENCY RELEASE

- Update Logic: Admin: Billing Resubmission for TH snapshot (OPTML-1905)
- Billing History Info Error need to add logic for TH snapshot (OPTML-1809)
`
  },
  {
    version: '4.3.0',
    date: '6/22/2024',
    notes: `    
  ### FEATURES
  
  - Display Patient Status: New, Refill, Seasonal, On Hold (OPTML-1734, OPTML-1803, OPTML-1775, OPTML-1777, OPTML-1778, OPTML-1799, OPTML-1801, OPTML-1805)
  - New Queue Filtering / Views for patient status (OPTML-1773, OPTML-1806)
  - Add Seasonal Address to Patient Info Tab (OPTML-1774)
  - New Work Item: Patient Reactivation Review (OPTML-1802, OPTML-1782, OPTML-1863)
  - New IVP (Intravenous Push) route of administration (OPTML-903)
  - Replace username manual enter on LabDraw closing with password checking (OPTML-1718)
  - TH Notes: need to keep a snapshot of Referral Order / Current Insurance (OPTML-1465, OPTML-1610, OPTML-1856)
  - New Data health check UI component (OPTML-1790, OPTML-1798)
  - Data Updates category, AIC chairs, Krystexa/Vyvgart (OPTML-1781, OPTML-1812, OPTML-1815, OPTML-1849)
  - Alert user if updating a Patients with same key information (OPTML-1830)
  - New Note Category: Payment (OPTML-1832)
  - New Document Type: Insurance Card (OPTML-1833)
  - Update Appointment Modal Cancellation Reasons (OPTML-1842)
  
  ### BUG FIXES
  
  - PDP program check for missing ndc's (OPTML-1789)
  - In the line flush section in nursing-Heparin is misspelled (OPTML-1827)
  - Follow Up Modal not working as expected (OPTML-1831)
  - Task Follow up Modal do not display "audience" (OPTML-1836)
  `
  },
  {
    version: '4.2.2',
    date: '6/6/2024',
    notes: `    
  ### EMERGENCY RELEASE
  
  - Inbound Fax Modal Issue with Z-Index (OPTML-1814)
  `
  },
  {
    version: '4.2.1',
    date: '5/29/2024',
    notes: `    
  ### EMERGENCY RELEASE
  
  - Reconstituted mix-ins not display in production (OPTML-1786)
  - Infusion Hamburger Menu is Not Displaying (OPTML-1787)
  `
  },
  {
    version: '4.2.0',
    date: '5/25/2024',
    notes: `    
  ### FEATURES
  
  - Queue Filtering By: Program, State, Drug Category, Insurance Company, Sales Territory (OPTML-1689, OPTML-1690, OPTML-1695, OPTML-1696, OPTML-1697, OPTML-1739)
  - Upload New Document: Ask (y/n) before creating work item (OPTML-1728, OPTML-1720)
  - Confirm yes or no before creating work item Inbound Fax (OPTML-1708)
  - Pre-Order: allow user to populate fields automatically (OPTML-1402)
  - Create work item for documents: Prior Auth Approved/Denied (OPTML-1764)
  - Update Master Product Table V46 (OPTML-1702)
  - Add new columns to view of Master Products - Category (OPTML-1715)
  - Update Patient Display Badge to show patients in "PDP" program instead of "Viiv" (OPTML-1727)
  - 18 New Follow-up Work Items for Clin, Sched, etc. (OPTML-1736)
  - Allow Nurse to override a Provider on Check-In Modal (OPTML-1743)
  - Dashes in phone number inbound fax/ etc (OPTML-1759)
  `
  },
  {
    version: '4.1.2',
    date: '5/2/2024',
    notes: `    
  ### EMERGENCY RELEASE
  
  - Clinical Review updating prescriber address to primary, should not update ever (OPTML-1687)
  `
  },
  {
    version: '4.1.1',
    date: '4/30/2024',
    notes: `    
  ### EMERGENCY RELEASE
  
  - Users cannot close non-call work items (OPTML-1709)
  - Some users cannot create a new order from the product selector (OPTML-1710)
  `
  },
  {
    version: '4.1.0',
    date: '4/27/2024',
    notes: `    
  ### FEATURES
  
  - Create a mini version of WI panel; allow user to retrieve from last queue worked (OPTML-1631, OPTML-964) 
  - Follow-Up Modal: Allow user to select Prescriber / Product Name and attach to Work Item (OPTML-1672)
  - Allow user to add Drug / Prescriber to Outbound Calls Work Items (OPTML-1643)
  - Update product name for "Nucala Syringe" NDC 00173-0892-42 (OPTML-1669)
  - Archive Order Modal: Hide discontinued options (OPTML-1670)
  - Hide Close Work Button on all Call Work items until after 3 attempts (OPTML-1679)
  - Clinical Q: Add Outbound Calls / Followups to Filter(OPTML-1681, OPTML-1679)
  - Patient Info Tab: Display all active patient referrals (OPTML-1693)
  
  ### BUG FIXES
  
  - 2nd Scheduling Text to patient (OPTML-1654)
  - Prevent user from creating a duplicate referral (OPTML-1685, 1686)
  - Navigation to Clinician Queue from Landing Page (OPTML-1650)
  - Follow-Up Modal inaccessible when work panel is open (OPTML-1664)
  `
  },
  {
    version: '4.0.9',
    date: '4/15/2024',
    notes: `    
  ### BUG FIXES
  
  - When2Work module is showing more Providers than are scheduled for the day (OPTML-1671)
  `
  },
  {
    version: '4.0.8',
    date: '4/13/2024',
    notes: `    
  ### FEATURES
  
  - Patient Work Item Log History (OPTML-1299)
  - Create Additional Drop Down Intake Queue Center to Allow Additional Sortation (OPTML-1517)
  - Add Follow-Up Calls: Schedule Patient, PA Denied (OPTML-1607)
  - Add Snooze functionality to all work items (OPTML-1530)
  - Update Widgets on Landing Page (OPTML-1552, OPTML-1661)
  - Dashboard: update columns with Totals on Right and bottom (OPTML-1624)
  - PA Screen-do not allow a user to select the box "PA complete" if the status is still pending (OPTML-1627)
  - Highlight Required Fields for (Ease of Use Enhancement): (OPTML-1640, OPTML-1645, OPTML-1646, OPTML-1647, OPTML-1648)
  - Update Complete Lab Draw Modal Dropdown Text (OPTML-1651)
  - Fax Queue Display Oldest Work First (OPTML-1657)
  `
  },
  {
    version: '4.0.7',
    date: '3/23/2024',
    notes: `    
  ### FEATURES
  
  - Clinician Q: Display work items (OPTML-735, OPTML-1570)
  - Schedule Q: Display work items (OPTML-1569)
  - Business Rule Change: Update CR if Referral is changed by a user (OPTML-1535)
  - New Admin feature: Unlock Patient (OPTML-1549)
  - Call Work Item Component: Update Text "fully resolved" (OPTML-1559)
  - New Call Work Items PA Denied Call, Need New Insurance (OPTML-1576, OPTML-1582)
  - Schedule Work Item > Texting, Add Patient Note Logic (OPTML-1578, OPTML-1579)
  - Add Patient Primary Phone number to Scheduling Work Item (OPTML-1580, OPTML-1593)
  - System Landing Page: Queue Center Widget - Add links Schedule and Clinician (OPTML-1598)
  
  ### BUG FIXES
  
  - Remove re-render on Call Work Items (OPTML-1398)
  - Extend Schedule Work Item not showing call history on log (OPTML-1590)
  `
  },
  {
    version: '4.0.6',
    date: '3/20/2024',
    notes: `    
  ### EMERGENCY RELEASE
  
  - Add phone no. validation to inbound Fax; create new patient (OPTML-1586)
  - Fix Billing Submission in Data Admin Error (OPTML-1588)
  - Extend Schedule not showing call history on log (OPTML-1590)
  `
  },
  {
    version: '4.0.5',
    date: '3/16/2024',
    notes: `    
  ### FEATURES
  
  - Display Product Status Correctly in Admin / Product Tab (OPTML-1381)
  - Update Rendering Provider text on treatment history notes (OPTML-1379)
  - Add logo to the treatment history notes (OPTML-1388)
  
  ### BUG FIXES
  
  - Add to inventory: fix the form logic (OPTML-1368)
  `
  },
  {
    version: '4.0.4',
    date: '3/14/2024',
    notes: `    
  ### EMERGENCY RELEASE
  
  - Fix issue with not all payers displaying (OPTML-1555, 1557)
  `
  },
  {
    version: '4.0.3',
    date: '3/12/2024',
    notes: `    
  ### FEATURES
  
  - Remove system generated Financial Assist. Work Item (OPTML-1518)
  - Allow user to create a Financial Assist Work Item (OPTML-1529)
  
  ### BUG FIXES
  
  - Inbound Fax: Insurance Dropdown Data, User Locking (OPTML-1483, 1531)
  - Follow Up Modal: Add  (Doctor) next to calls items (OPTML-1513)
  - Display Intended Audience for Work Items in Descriptions and Queue Center (OPTML-1516)
  - Referral Created at Date (OPTML-1541)
  `
  },
  {
    version: '4.0.2',
    date: '3/5/2024',
    notes: `    
  ### FEATURES
  
  - Work Item: Re-verification for insurance added to BI Queue (OPTML-1473)
  - Work Items: Remove Scheduling (OPTML-1479)
  - Site Inventory: performance enhancement (OPTML-1503)
  - Update provider status for Vu Tran to Active in Production Environment (OPTML-1508)
  
  ### BUG FIXES
  
  - Dashboard: Display CREATED Work Items (OPTML-1477)
  - Billing: Not able to see Billing detail (OPTML-1486)
  - Patient Log not working as expected (OPTML-1505)
  `
  },
  {
    version: '4.0.1',
    date: '3/4/2024',
    notes: `    
  ### FEATURES
  
  - Add snooze to welcome/financial work items (OPTML-1480)
  
  ### BUG FIXES
  
  - Add Site to Inventory: update filtering to include ALL products (OPTML-1482)
  - Display phone no. on welcome/financial work items (OPTML-1475)
  `
  },
  {
    version: '4.0',
    date: '3/2/2024',
    notes: `    
  ### FEATURES
  
  - New Inbound Fax Queue
  - New Queue Work Flow (Intake, BI, PA)
  - Dashboard
  - Pre-Order Referral Process Changes (OPTML-1260)
  `
  },
  {
    version: '3.15.0',
    date: '2/3/2024',
    notes: `    
  ### FEATURES
  
  - Add to Site Inventory > Free Drug Flag (OPTML-1275)
  - Free Drug: new vendors, remove PAP prefix for PAP vendors (OPTML-1332)
  - Diluent / Reconstituted "with" changing TEXT (OPTML-1333)
  `
  },
  {
    version: '3.14.5',
    date: '1/25/2024',
    notes: `    
  ### BUG FIXES
  
  - Remove validation and allow for decimals in dosage field under Referral Admin (OPTML-1309)
  `
  },
  {
    version: '3.14.4',
    date: '1/18/2024',
    notes: `    
  ### BUG FIXES
  
  - Lists of payers in admin view and patient insurance are different (OPTML-1297)
  `
  },
  {
    version: '3.14.3',
    date: '1/17/2024',
    notes: `
  ### FEATURES
  
  - Lab Draw Modal: add CPT Codes for Access Point (OPTML-1280)
  - Lab Draw: Access Point billing - add CPT Codes (OPTML-1281)
  
  ### BUG FIXES
  
  - getPayersByInsurerName returns data in chunks, while we expect as a whole thing (OPTML-1288)
  `
  },
  {
    version: '3.14.2',
    date: '1/13/2024',
    notes: `
  ### FEATURES
  
  - Lab Draw Modal: Allow nurse to select "Access Point" billing code (OPTML-1227)
  - TH Note: Display Access Point Selected by Nurse (OPTML-1265)
  - Lab Draw Referral: Allow Multiple ICD-10 codes to be selected, displayed, updated (OPTML-1243)
  - Add new document type: Lab Requisition (OPTML-1229)
  - Lab Draw Referral: Bill for Multiple ICD-10 codes (OPTML-1248)
  - Update document type: Lab Test to "Lab Test Results" (OPTML-1249)
  - Add validation to Referral/Lab Draw Admin for only numerals (OPTML-1228)
  - Patient Overview Page: do not display any lab draw information (OPTML-1273)
  
  ### BUG FIXES
  
  - Check Created at Date is not null in new referrals (OPTML-1256)
  - Referral Admin not clearing context (OPTML-1241)
  `
  },
  {
    version: '3.14.1',
    date: '1/3/2024',
    notes: `
  ### FEATURES
  
  - Medicaid Compliance for Treatment History Fax (OPTML-1221)
  
  ### BUG FIXES
  
  - Referral attach document not displaying as expected (OPTML-1235)
  `
  },
  {
    version: '3.14',
    date: '12/23/2023',
    notes: `
  ### FEATURES
  
  - Disable fax template for refills (OPTML-1015)
  - Create New Lab Draw Order (OPTML-1180)
  - Add New Document Type: Lab Draw for Upload (OPTML-1182)
  - Scheduler: Display Lab Draw Appointment on Calendar (OPTML-1184)
  - Scheduler: Lab Draw Appt. Check In, Reschedule, Cancel (OPTML-1185)
  - Modal: Perform Lab Draw > check in > record lab draw (OPTML-1187)
  - TH Note: Display Lab Draw Results (OPTML-1186)
  
  ### BUG FIXES
  
  - Patient Look-up can only select ID, need to add Name also to selection (OPTML-1218)
  `
  },
  {
    version: '3.13.4',
    date: '12/2/2023',
    notes: `
  ### FEATURES
  
  #### System Enhancements
  
  - Release AQCCA tablet surveys to all AIC centers (OPTML-1169)
  `
  },
  {
    version: '3.13.3',
    date: '11/28/2023',
    notes: `
  ### FEATURES
  
  #### System Enhancements
  
  - AQCCA: Survey Updates (OPTML-1155)
  `
  },
  {
    version: '3.13.2',
    date: '11/13/2023',
    notes: `
  ### FEATURES
  
  - If PHQ9 score 0 - still need to save that in meta score object (OPTML-1071)
  - Additional Prescriber Data Elements Visibility (OPTML-1118)
  - Referral New Order Tab: Display NPI on Prescriber Selector (OPTML-1141)
  - Main Search Results include first name sorting (OPTML-1140)
  
  ### BUG FIXES
  
  - Fax Preview: when there's an error from server on fax generation process - spinner will keep going endlessly (OPTML-1117)
  `
  },
  {
    version: '3.13.1',
    date: '11/11/2023',
    notes: `
  ### FEATURES
  
  - Tablet Survey: Implement survey review by nurse on tablet (OPTML-1108)
  - Survey Tablet: sort patient search results A-Z (OPTML-1131)
  - Infusion Survey: add check status to the survey banner to check if review is complete (OPTML-1132)
  - Fax: Add new Logo to Outgoing Fax (OPTML-1092)
  `
  },
  {
    version: '3.13',
    date: '10/31/2023',
    notes: `
  ### FEATURES
  
  - AQCCA Patient Survey on Tablet
  - Key Patient Insights from Survey added to TH/Chart Notes (OPTML-1066)
  - Close treatment: Allow Nurse to Skip Survey (OPTML-1074)
  - Close treatment: Review and Edit patient answers to the tablet survey (OPTML-1076)
  `
  },
  {
    version: '3.12',
    date: '10/18/2023',
    notes: `
  ### HOTFIX
  
  - Display Nurse Schedule from W2W on Calendar for all AIC Centers (OPTML-1030)
  - Log current app version when app starts / updates / reloads (OPTML-1020)
  `
  },
  {
    version: '3.11',
    date: '10/2/2023',
    notes: `
  ### HOTFIX
  
  - Current Order: prescriber and address does not refresh (OPTML-974)
  - Add new AIC locations to AQCCA list of participants (OPTML-1005)
  - Hide Target Injection Dates on Appointment Modal (OPTML-1012)
  `
  },
  {
    version: '3.10.2',
    date: '8/26/2023',
    notes: `
  ### HOTFIX
  
  - Add Solumedrol to pre-meds list (OPTML-908)
  `
  },
  {
    version: '3.10.1',
    date: '8/9/2023',
    notes: `
  ### HOTFIX
  
  - Add New Vendors to Inventory Selector (OPTML-882)
  `
  },
  {
    version: '3.10',
    date: '7/29/2023',
    notes: `
  ### FEATURES
          
  #### System Enhancements
  
  - Update Archive Reasons for Referral (VIIV-844)
  - New Codes added for Referral Discontinuation Survey (VIIV-845)
  - Appointment Text Message Updated (VIIV-856)
  `
  },
  {
    version: '3.9',
    date: '7/8/2023',
    notes: `
  ### FEATURES
          
  #### New Features
  
  - Prescriber: FAX OPT OUT functionality (VIIV-613, VIIV-760, VIIV-761, VIIV-768)
  - Twillio - messaging updates from Business (day 1, 3 and 7) (VIIV-775, VIIV-776)
  - Add new patient note category types (VIIV-796)
  - Add D5 (dextrose 5% solution) as diluent (VIIV-770)
  - Appointment Check-In: Add Order notes from referral/Current order to the appointment check-in modal (VIIV-193)
  
  #### System Enhancements
  
  - Keep User from adding Bad Prescriber NPI's (VIIV-785)
  - UI improvements for Insurance Plan selector (VIIV-740, VIIV-743)
  - Update logo in the infusion portal layout (VIIV-791)
  - Prior Auth - revisit logic to include start/end dates (VIIV-765)
  
  ### BUG FIXES
  
  - Prevent Future Patient Weights from being added to the system (VIIV-766, VIIV-787)
  - Presciber Fax not updating in Patient Header (VIIV-769)
  - Fix gaps in main layout (VIIV-774, VIIV-806)
  - Patient Appointment Tab Details not showing admin seq. no. (VIIV-800)
  `
  },
  {
    version: '3.8.1',
    date: '6/20/2023',
    notes: `
  ### HOTFIX
      
  - Layout refactoring issues (VIIV-763)
  - Archive creating duplicate orders (VIIV-773)
  `
  },
  {
    version: '3.8',
    date: '6/10/2023',
    notes: `
  ### FEATURES
          
  #### New Features
  
  - New Logo: Update UI with the new Aleracare and OptmlInfuse Logos (VIIV-692, VIIV-732)
  - Add New logos to Login page (VIIV-730, VIIV-733, VIIV-738)
  - UUID all referrals (VIIV-724, VIIV-737)
  
  #### System Enhancements
  
  - Inventory - Update Front End Logic on Add New Drug Modal for Sites (VIIV-708)
  - Add Rule to Validate PA Total no. of Units (VIIV-177)
  - Infusion Portal layout refactoring (VIIV-718)
  
  ### BUG FIXES
  
  - Fix: notes being deleted (VIIV-673)
  - Fix: don't allow user to start multiple infusions (VIIV-726)
  - Fix: Lab Date Picker Expiration date: allow user to enter the year and keep validation (VIIV-729)
  `
  },
  {
    version: '3.7.1',
    date: '5/25/2023',
    notes: `
  ### FEATURES
          
  #### System Enhancements
  
  - Create New User Role for Provider Update: Nursing Admin (VIIV-694, VIIV-709, VIIV-710)
  - Add date validation to prevent users from entering 0023 for year (VIIV-663)
  
  ### BUG FIXES
  
  - Fix: Prescriber Data in Patient Details Header (VIIV-679)
  - Do not allow users to click on Add Insurance button if no Insurance Company was selected (VIIV-681)
  `
  },
  {
    version: '3.7',
    date: '5/20/2023',
    notes: `
  ### FEATURES
  
  #### New Features
  
  - Pending Appointments (VIIV-622, VIIV-623, VIIV-674)
  - Allow to update Provider on a nursing process (VIIV-556)
  - Pre-Medications: implement logic from dropdown list eliminate data entry (VIIV-602)
  - Lab Test Update: eliminate data entry for certain lab tests (VIIV-601, VIIV-676)
  
  #### AQCCA
  
  - Survey opt-out on patient level (VIIV-567)
  - Skipping survey (VIIV-568)
  - Do not show comparison panel if survey was skipped (VIIV-649)
  - Filter out skipped surveys for scores comparison (VIIV-650)
  - Do not display survey if patient opted-out from all surveys (VIIV-657)
  - Opting-out from surveys right from an active survey (VIIV-658)
  - Extend current opt-out logic on patient level (VIIV-668)
  - Update reasons list for skip and opt-out survey actions (VIIV-675)
  
  #### System Enhancements
  
  - Archive referral history - need to add prescriber address field, account for multiple addresses (VIIV-642)
  - Look for any other Prescriber API's that need updating (VIIV-629)
  - Update infusion provider: get providers list for all providers, not just attached to a location (VIIV-660)
  - Switch modal component for Cancel Infusion to Window (VIIV-669)
  - Add validation rules to the front end to prevent users from entering non-UTF-8 characters in policy id fields (VIIV-656)
  - Archive Referral History Tab - display additional prescriber address if user chooses one other than primary (VIIV-643)
  - Create Prescriber Info - make sure name is saved as all lower case (VIIV-611)
  
  ### BUG FIXES
  
  - DrawerItem uses a prop that doesn't exists (VIIV-648)
  - Appointment tab: link to calendar is missing in production (VIIV-634)
  `
  },
  {
    version: '3.6.1',
    date: '5/5/2023',
    notes: `
  ### HOTFIX
      
  - Patient Treatment History Note -- update fields to reflect prescriber changes (VIIV-626, VIIV-631)
  - Referral History Tab -- add fields (VIIV-625)
  - Appointment tab: link to calendar is missing in production (VIIV-634)
  `
  },
  {
    version: '3.6',
    date: '4/29/2023',
    notes: `
  ### FEATURES
  
  #### System Enhancements
      
  - Upgrade location selector to a new version in specific views (VIIV-573)
  - Prevent redirect from Infusion Queue for inactive locations (VIIV-618)
  - Adding the "appointment" tab to infusion portal view (VIIV-513)
  - Overview Page: Add sorting functionality to Patient Documents Grid (VIIV-525)
  
  #### System Enhancements
  
  - Work in Progress redesign (VIIV-343)
  - AQCCA: Implement frequency range based on previous results (VIIV-545)
  - Prescribers: Update to allow for multiple prescriber addresses:
      - UI update for multiple prescriber addresses (VIIV-402)
      - New Order - add new logic for prescriber address selector (VIIV-403)
      - Current Order - display prescriber's office address and fax number; allow to change/update address (VIIV-404)
      - Add New Prescriber Modal - check to make sure current logic works with new prescriber enhancements (VIIV-524)
      - Exclude address from the list or make it non-selectable if it doesn't have fax number (VIIV-607)
      - Show warning / highlight address card if the address doesn't have fax number (VIIV-608)
      - Prescriber: Search by Last Name / NPI enhancements (VIIV-447) (VIIV-526)
      - Add backend logic to fax TH note to the correct address/fax (VIIV-599)
      - Prescriber: Make Fax No. field required (VIIV-605)
      - Add badge to show empty fax/phone numbers (VIIV-606)
  - Archive Order / Duplicate Referral:
      - Add date field for original referral order document to the referral (VIIV-536)
      - Create new order based on the data from archived order (VIIV-407)
      - Archive order logic moves to a modal (VIIV-406)
      - Archive order: add logic for Original Received Date timestamp (VIIV-588)
  
  #### Bug Fixes
  
  - When hovering over an All-Day Note, a weird, empty box appears when it should not (VIIV-460)
  - Incorrect appt time for Twillio notes (VIIV-500)
  - After clicking on a discontinued order, the modal displays "Archived Referral Order Details" and not "Discontinued Referral Order Details" (VIIV-507)
  - Overview Page Displays Order Expires - No referral is created (VIIV-528)
  - Archived order does not display multiple primary DX codes (VIIV-596)
  `
  },
  {
    version: '3.5.2',
    date: '4/15/2023',
    notes: `
  ### FEATURES
  
  - Add logic that prevents patient docs being deleted for some specific doc types (VIIV-577)
  - Add Patient Rights and Responsibilities Document to all current patients (VIIV-582)
  `
  },
  {
    version: '3.5.1',
    date: '4/11/2023',
    notes: `
  #### Bug Fixes
  
  - Referral Fax Attachment not clearing out (VIIV-557)
  `
  },
  {
    version: '3.5',
    date: '4/1/2023',
    notes: `
  ### FEATURES
  
  #### System Enhancements
  
  - Failed Faxes Queue (VIIV-115)
  - PDF Export of treatment note refactoring (VIIV-371)
  - Treatment History Tab -- Add Date Range Picker (VIIV-398)
  - Design and functionality changes for a hamburger menu (VIIV-448)
  - Add Prednisone to Premed dropdown list in OptML (VIIV-480)
  - Prevent sending faxes from dev or staging (VIIV-508)
  - DATA CLEANSE: referrals to be archived/cancelled out of OptML (VIIV-438)
  - Register Twilio for TCR business SMS Registration (VIIV-452)
  
  #### New Features
  
  - AQCCA - Edits to existing questions (re-wording) (VIIV-130)
  - AQCCA - add 2 new participated locations (VIIV-400)
  - AQCCA - add 2 guides and related logic (triggers) (VIIV-416)
  - AQCCA - revise survey questions, v2 (VIIV-417)
  - AQCCA - survey prompts logic to be added (VIIV-418)
  - AQCCA - Adjust survey comparison logic based on survey version (VIIV-492)
  - AQCCA - Provide updated AQCCA results grid for version 2 (VIIV-493)
  - AQCCA - Apply new result grid for version 2 (VIIV-494)
  - Add System Timestamp to all incoming fax documents:
      - Upload Tab: add timestamp feature (VIIV-464)
      - New Referral: add updates to attach referral and collect system rec'd date (VIIV-465 
      - Current Referral: display timestamp rec'd and allow admin to update (VIIV-466)
      - Overview Page: Patient Docs – Display timestamp System Received in grid (VIIV-468)
  - Treatment History - Details - fix spacing (VIIV-490)
  - Display New Note Type/Categories (VIIV-509) (VIIV-510)
  
  #### Bug Fixes
  
  - Patient Documents, fixes for delete, display, window only renders on first document upload, document list is not refreshed (VIIV-376, VIIV-378, VIIV-379, VIIV-380, VIIV-397)
  - EXTEND_PA_VISITS Work Item isn't clearing out (VIIV-399)
  - Treatment history Notes original Order Weight displays incorrectly (VIIV-409)
  - Archive/ Add New Referral Order Name Issue (A) (VIIV-411)
  - Attempting to log in without a password is caught, but results in a bad error message (VIIV-414)
  - When app update is available - it shows the modal and immediately closes it (VIIV-478)
  - Patient Overview Tab: Labs - not updating when lab is deleted (VIIV-496)
  `
  },
  {
    version: '3.4.1',
    date: '3/11/2023',
    notes: `
  ### HOTFIX
      
  - Check-in Modal not retaining provider information (VIIV-408)
  - Statusbar: PA displays red when no PA is needed in the BI tab (VIIV-445)
  - Fax History: Display reason fax failed (VIIV-440)
  `
  },
  {
    version: '3.4',
    date: '3/4/2023',
    notes: `
  ### FEATURES
  
  #### System Enhancements
  
  - Auto log-off after 6 hours user's idling (VIIV-277)
  - Update PA: to Accommodate for Rule to Invalidate Appointments for PA # of Units Over the limit (VIIV-236)
  
  #### Billing
  
  - Reset the flag: isRegisteredWithR2 make sure system is sending patients latest insurance changes to R2 (VIIV-284)
  
  #### VIIV-Enhancements
  
  - Display VIIV banner if patient is participating the VIIV program (VIIV-301)
  - Display Stat VIIV Banner in the Intake Queue (VIIV-296)
  
  #### Calendar Enhancements
  
  - Null Appointment Fix: Archived / Add new Referral to Appt. add logic for appts > 15 day window (VIIV-359)
  - Cancel Appointment Modal: New Reason “Archived Referral” added (VIIV-365)
  
  #### New Faxes
  
  - Fax Patient Has Completed their Scheduled Appointment (VIIV-122)
  
  #### New Features
  
  - Failed Faxes Queue: Display all failed faxes, ability to search by specific date range (VIIV-353, VIIV-313)
  
  #### Bug Fixes
  
  - Current Patient Appointments overlaps the other content supposed to be in front of it (VIIV-288)
  - Fixed SSN validation / masking / formatting issues (VIIV-311)
  - Calendar: Appointment does not show on calendar if it is made outside of business hours (VIIV-322)
  - Calendar: ALL the pts on the calendar today at Central have the "(A)" in the Appointment Tab (VIIV-210)
  - Infusion, Pre-Treatment Step: loosing saved initials while switching between tabs (VIIV-324)
  - Infusion, Pre-treatment, PIV(1): Attempt make sure data is being saved (VIIV-327)
  - UI adherence - Infusion - Patient Notes modal allow to see background (VIIV-342)
  - Patient Overview – Overview Notes not displaying when saved (VIIV-333)
  - New release modal shows up in the login form (VIIV-335)
  - Unable to delete Timely Filing under Claims in the BI (VIIV-358)
  - Patient Doc disappears after you Upload File (VIIV-357)
  - Current Calculated Dosage conversion is calculating wrong for weight-based drugs for calculated dosage entered (VIIV-302)
  - Infusion, Admin Step: negative remaining value displayed (VIIV-325)
  `
  },
  {
    version: '3.3.2',
    date: '2/3/2023',
    notes: `
  ### HOTFIX
  - Issue when switching between Patient Docs and Notes (VIIV-314)
  - Unable to update DX code in current referrals (VIIV-315)
  - Infusion End Time Bug Fix - remove yellow warning status that is currently showing during infusion steps (VIIV-319)
  `
  },
  {
    version: '3.3.1',
    date: '2/2/2023',
    notes: `
  ### HOTFIX
  
  - New Order - Weight-based Drugs: fix conversion issue when user enters patients weight in KG’s (VIIV-297)
  `
  },
  {
    version: '3.3',
    date: '1/27/2023',
    notes: `
  ### OVERVIEW
  
  Release of VIIV Phase 1 as well as Enhancements for Calendar, Patient Info Tab, Archiving referrals.
  
  ### FEATURES
  
  #### System Enhancements
  
  - Speed Up Loading of All Queues (VIIV-240)
  - System Release Notes (notification system) - VIIV-151
  
  #### Patient Information Tab
  
  - Patient Info Tab Updates (VIIV-5)
  - SSN - patient Information add masking to hide first 6 digits (VIIV-157)
  - Add Validation for Zip Codes in Patient Information Tab (VIIV-158)
  - Patient Info: Cell Phone validation (VIIV-209)
  
  #### VIIV-10 Enhancements for Viiv (Cabenuva, Apretude)
  
  - Implement Concept of a Program to Aleracare Schema (VIIV-169)
  -  Need to accommodate Combination Drugs / Dual Dosing (VIIV-52)
  - ViiV Referral: new way to handle administrations (VIIV-235)
  - Apretude Referral: Negative Infection Status Confirmed is mandatory before we start the infusion (VIIV-224)
  - Dual dosage display for Cabenuva: Calendar and Infusion (VIIV-226)
  - ViiV Referral: Lab results - extend current Labs / Tests component (VIIV-227)
  - ViiV Referral: add Oral Lead-in Therapy component (VIIV-228)
  - Add Dosing Window to Schema (VIIV-167)
  - ViiV Referral: Add Oral Lead In logic to the current orders view (VIIV-232)
  
  #### Archive Referral
  - Prevent user from archiving a referral if there is an in-progress infusion associated to the referral (VIIV-150)
  
  #### Calendar Enhancements
  
  - Integrate new location dropdown everywhere at Calendar level (VIIV-287)
  - Calendar: Target Injection Dates (VIIV-231)
  - Calendar (ViiV Appointment): link Oral Lead In logic with Target Injection Dates (VIIV-234)
  - Calendar add reason code for appointment "reschedule" or "cancel" -- Need Labs (VIIV-225)
  
  #### New Faxes
  
  - Faxing (new fax templates) - VIIV-120:
  -- patient has been scheduled
  -- patient has been scheduled but declined onboarding
  -- patient has completed appointment
  
  #### Bug Fixes
  
  - FrontEnd - Who is at risk for claims field in Benefits Inv does not save or write to back end (VIIV-159)
  - If user has default location that is inactive - it causes problems with infusions (VIIV-241)
  - Infusion: IV header fix UOM (VIIV-289)
  `
  }
];
