import React, { useEffect, useContext, useMemo, useState } from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Form, Field } from '@progress/kendo-react-form';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import Alert from '@/components/common-components/Alert';

import {
  validateInput,
  validateNumeric,
  DatePickerField,
  InputField
} from '@/common/Validation';
import {
  formatDateTimeToDefault,
  formatDateToAWS,
  formatDateToDefault
} from '@/common/DateHelper';
import {
  isWeightBasedUOM,
  calcRemaining,
  getConversionRate,
  getDosageUOM
} from '@/common/DoseHelper';
import {
  diluentValidators,
  formatDiluentProductName,
  drugDosage,
  stingsAreEqual,
  parseNumber,
  findMinVialStrength,
  getStepStatusV2,
  infusionSteps,
  infusionStepNumbersV2,
  printIvLabel,
  listPatientAllergies,
  findPrescriberById
} from '../Infusion/infusionHelper';

import { MessageDialog } from '@/components/common-components/MessageDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
import RevisitPrep from '../Infusion/Revisit/RevisitPrep';
import IVLabel from '../Infusion/IVLabel';
import PatientWeightLog from '../Infusion/PatientWeightLog';

import { Constants } from '@/constants';

import { connectToGraphqlAPI } from '@/provider';
import {
  getInventoryByLocation,
  listDiluentProducts,
  listReconstitutedProducts
} from '@/graphql/queries';
import { updateStepPreparation } from '@/graphql/mutations';

import { UserContext } from '@/context/UserContext';
import { InfusionContext } from '@/context/InfusionContext';
import { NotifContext } from '@/context/NotifContext';
import { getCabenuvaDosageLabel, isViiVProduct } from '@/common/viivHelper';

const PrepV2 = (props) => {
  // App Context
  const { isClinicalAdmin, agent } = useContext(UserContext);
  const { showWarning, showError } = useContext(NotifContext);
  const { infusion, setInfusion, setCurrentStep, updateStepper } =
    useContext(InfusionContext);

  const { nursingProcess, scheduleEvent, currentReferral, patientBucket } =
    infusion;
  const nursingProcessId = nursingProcess?.id;
  const adminNumber = scheduleEvent?.adminSequenceNumber;

  const [itemWeights, setItemWeights] = useState([]);
  const [apiDrugs, setApiDrugs] = useState([]);
  const [itemDrugs, setItemDrugs] = useState([]);
  const [apiDiluents, setApiDiluents] = useState([]);
  const [itemDiluents, setItemDiluents] = useState([]);
  const [apiReconstituteds, setApiReconstituteds] = useState([]);
  const [itemReconstituteds, setItemReconstituteds] = useState([]);

  const [diluentList, setDiluentList] = useState([]);
  const [mixinList, setMixinList] = useState([]);

  const [stepAllowed, setStepAllowed] = useState(false);
  const [dialogOption, setDialogOption] = useState({});

  const [showPrintIVLabel, setShowPrintIVLabel] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [isEmptyInventory, setIsEmptyInvertory] = useState(false);
  const [isPreparationCompleteSwitch, setIsPreparationComplete] =
    useState(false);

  const [stepInitCompleted, setStepInitCompleted] = useState(false);

  // conversion rate related stuff:
  const [productUOM, setProductUOM] = useState(null);

  const setDefaultDosageState = () => {
    const hasDrugsInStore = Boolean(
      nursingProcess &&
        nursingProcess.stepPreparation &&
        nursingProcess.stepPreparation.drugs &&
        nursingProcess.stepPreparation.drugs.length > 0
    );

    const hasPatientWeight = Boolean(
      nursingProcess &&
        nursingProcess.stepAssessment &&
        nursingProcess.stepAssessment.patientWeights &&
        nursingProcess.stepAssessment.patientWeights.length > 0
    );

    // last record in the array will always be CURRENT WEIGHT
    const patientWeight = hasPatientWeight
      ? nursingProcess.stepAssessment.patientWeights[
          nursingProcess.stepAssessment.patientWeights.length - 1
        ].weight
      : 0;

    const getAdminSeqData = () => {
      const data = { calcDosage: 0, unitOfMeas: '', dose: 0 };

      if (adminNumber) {
        const adminSeq = currentReferral?.referralOrder?.administrations.find(
          (admin) => admin.adminSequenceNumber === adminNumber
        );

        if (adminSeq) {
          return adminSeq;
        }
        return data;
      }

      return data;
    };

    const adminSeqData = getAdminSeqData();
    const { calcDosage } = adminSeqData;
    const UOM = adminSeqData.unitOfMeas; // order level UOM
    const weightBasedDrug = isWeightBasedUOM(UOM);
    const storedDrugInfo = nursingProcess?.stepPreparation?.drugs[0];

    const adjustedDosage = hasDrugsInStore
      ? storedDrugInfo.adjustedDosage || 0
      : 0;
    const selectedQuantity = hasDrugsInStore
      ? storedDrugInfo.selectedQuantity || 0
      : 0;
    const wastage = hasDrugsInStore ? storedDrugInfo.wastage || 0 : 0;

    const pUOM = hasDrugsInStore ? storedDrugInfo.uom : UOM;
    const conversionRate = hasDrugsInStore ? 1 : getConversionRate(UOM, pUOM);

    // for weight-based drugs only, keep for nurse's reference and never change manually
    // const origDrugDose = adminSeqData.dose;
    const origDrugDose = parseNumber(+adminSeqData.dose / conversionRate);

    const calculatedDosage =
      weightBasedDrug && hasPatientWeight ? patientWeight * origDrugDose : 0;

    // new field - remaining qty
    const remainingQty = calcRemaining({
      wastage,
      adjustedDosage,
      selectedQuantity,
      calcDosage: storedDrugInfo?.calcDosage || origDrugDose,
      calculatedDosage
    });

    return {
      calcDosage,
      adjustedDosage,
      selectedQuantity,
      wastage,
      UOM,
      weightBasedDrug,
      calculatedDosage,
      remainingQty,
      conversionRate,
      patientWeight
    };
  };

  const [drugReady, setDrugReady] = useState(true);
  const [dosage, setDosage] = useState({});

  useEffect(() => {
    if (stepInitCompleted) {
      setDosage(setDefaultDosageState());
    }
  }, [stepInitCompleted, adminNumber]);

  useEffect(() => {
    if (productUOM) {
      const conversionRate = getConversionRate(dosage.UOM, productUOM);
      setDosage({
        ...dosage,
        adjustedDosage: dosage.adjustedDosage,
        calcDosage: parseNumber(+dosage.calcDosage / conversionRate),
        calculatedDosage: parseNumber(
          +dosage.calculatedDosage / conversionRate
        ),
        remainingQty:
          apiDrugs.length > 0
            ? dosage.remainingQty
            : parseNumber(+dosage.remainingQty / conversionRate),
        selectedQuantity: dosage.selectedQuantity,
        wastage: dosage.wastage
      });
    }
  }, [productUOM, dosage.UOM]);

  const findAdminSeqByNumber = (sequenceNumber) => {
    const seqNumber = sequenceNumber || 1;

    return currentReferral?.referralOrder?.administrations.find(
      (admin) => admin.adminSequenceNumber === seqNumber
    );
  };

  const getLabelData = () => {
    let uom = '';
    let calcDosage = '-';
    let origDosage = '-';
    let adjustedDosage = '-';

    const administration = findAdminSeqByNumber(adminNumber);
    if (administration) {
      uom = administration.unitOfMeas;
      origDosage = `${administration.approvedDosage} ${uom}`;
    }

    // this will help to convert weight-based UOMs
    const convertedUom = getDosageUOM(uom);

    // originally calculated dosage on the order level:
    if (dosage.calcDosage > 0) {
      calcDosage = `${dosage.calcDosage} ${convertedUom}`;
    }

    // if weight was entered on Assessment step:
    if (dosage.calculatedDosage > 0) {
      const weightBasedDosage = administration.dose * dosage.patientWeight;
      calcDosage = `${weightBasedDosage} ${convertedUom}`;
    }

    if (dosage.adjustedDosage > 0) {
      adjustedDosage = `${dosage.adjustedDosage} ${convertedUom}`;
    }

    // dual-dosing labels need to be added
    if (currentReferral?.isCabenuva) {
      const cabenuvaLabel = getCabenuvaDosageLabel(dosage.calcDosage);
      origDosage = cabenuvaLabel;
      calcDosage = cabenuvaLabel;
    }

    const referralPrescriber = findPrescriberById(
      patientBucket,
      currentReferral?.prescriberId
    );
    const prescriber = referralPrescriber
      ? `${referralPrescriber.prescriberFirstName} ${referralPrescriber.prescriberLastName}`
      : 'n/a';

    return {
      patientName: `${patientBucket.patientFirstName} ${patientBucket.patientLastName}`,
      patientId: patientBucket.patientId,
      patientAllergies: listPatientAllergies(
        patientBucket.patientProfile.allergyInfo
      ),
      prescriber,
      dob: formatDateToDefault(patientBucket.dob),
      productName: currentReferral?.drugName,
      productCode: currentReferral?.drugId,
      agent: agent.agentId,
      datePrinted: formatDateTimeToDefault(),
      calcDosage,
      origDosage,
      adjustedDosage
    };
  };

  const labelDataNew = getLabelData();

  const validateNumber = (value) =>
    validateNumeric(value) ? '' : Constants.ErrorMessage.Numeric_Required;

  const calcWastage = (
    selectedQuantity,
    orderDosage,
    adjustedDosage,
    calculatedDosage
  ) => {
    const calcDosage = Number(
      adjustedDosage || calculatedDosage || orderDosage
    );

    if (selectedQuantity > calcDosage) return selectedQuantity - calcDosage;

    return null;
  };

  const calcSelectedQuantity = (drugs) =>
    drugs.reduce(
      (accumulator, { strength, quantity }) =>
        // parseNumber(accumulator + strength * quantity * rateUOM),
        parseNumber(accumulator + strength * quantity),
      0 // 0 - initial qty
    );

  // MAIN INITIATOR
  useEffect(() => {
    getDulientProductsCall(); // setting up dulients from server
    getReconstitutedProductsCall(); // setting up reconstituted drugs from server
  }, []);

  useEffect(() => {
    handleLoadInfusion();

    if (nursingProcess.locationId) {
      listLocationInventoryCall(nursingProcess.locationId);
    }
  }, [nursingProcess]);

  const updateStepPrepCall = async (input) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepPreparation,
        variables: { input }
      });
      if (data && data.data && data.data.updateStepPreparation) {
        setCurrentStep(infusionStepNumbersV2.ADMIN);

        const infusionData = { ...infusion };
        infusionData.nursingProcess.stepPreparation = {
          ...infusionData.nursingProcess.stepPreparation,
          diluent: [...input.diluent],
          drugs: [...input.drugs],
          reconstitutedIn: [...input.reconstitutedIn],
          preparationComplete: input.preparationComplete
        };

        // update infusion data in the context level
        setInfusion({ ...infusionData });

        // update stepper progress checkmark
        const stepIsValid = getStepStatusV2(infusionSteps.PREP, input);
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.error('PrepV2::updateStepPrepCall err: ', err);
    }
  };

  const listLocationInventoryCall = async (locationId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getInventoryByLocation,
        variables: { locationId }
      });

      if (data?.data?.getInventoryByLocation?.items) {
        /// /////////////////////
        let list = data.data.getInventoryByLocation.items;
        let sNextToken = data.data.getInventoryByLocation.nextToken
          ? data.data.getInventoryByLocation.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const nextData = await connectToGraphqlAPI({
              graphqlQuery: getInventoryByLocation,
              variables: { locationId, nextToken: sNextToken }
            });

            if (nextData?.data?.getInventoryByLocation?.items) {
              sNextToken = nextData.data.getInventoryByLocation.nextToken
                ? nextData.data.getInventoryByLocation.nextToken
                : null;
              list = list.concat(nextData.data.getInventoryByLocation.items);
            }
          } catch (err) {
            console.error('PrepV2::listLocationInventoryCall err: ', err);
            sNextToken = null; // set to stop iterating
          }
        }
        /// ////////////////

        const onlySimilarDrugs = list
          .filter((item) =>
            stingsAreEqual(currentReferral.drugName, item.productName)
          )
          .filter((item) => item.currentQuantity > 0); // <--- exclude everything that has 0 or negative QTY in the inventory

        if (onlySimilarDrugs.length > 0) {
          const mappedDrugs = onlySimilarDrugs
            .map((item) => {
              const prefix = item.freeDrug ? '(FREE DRUG) ' : '';
              item.optionText = `${prefix}${item.productName} ${
                item.strengthPerVial
              } ${item.unitOfMeasure}, LOT# ${item.lotNumber} (${
                item.currentQuantity
              } in stock), EXP.Date ${formatDateToDefault(
                item.expirationDate
              )}`;
              return item;
            })
            .sort((a, b) => (a.productName > b.productName ? 1 : -1));

          setIsEmptyInvertory(false);
          setInventoryData(mappedDrugs);

          // define UOM at the product level
          setProductUOM(mappedDrugs[0].unitOfMeasure);
        } else {
          setIsEmptyInvertory(true);
        }
      } else {
        showWarning('NO INVENTORY DATA AVAILABLE.');
      }
    } catch (err) {
      console.error('PrepV2::listLocationInventoryCall err: ', err);
    }
  };

  const getDulientProductsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listDiluentProducts
      });

      if (data.data?.listProducts?.items) {
        setDiluentList(
          data.data.listProducts.items
            .map((item) => {
              item.optionText = formatDiluentProductName(
                item.productName,
                item.strength,
                item.unitOfMeas
              );
              return item;
            })
            .sort((a, b) => (a.productName > b.productName ? 1 : -1))
        );
      } else {
        setDiluentList([]);
      }
    } catch (err) {
      console.error('PrepV2::getDulientProductsCall err: ', err);
    }
  };

  const getReconstitutedProductsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listReconstitutedProducts
      });

      if (data.data?.listProducts?.items) {
        setMixinList(
          data.data.listProducts.items
            .map((item) => {
              item.optionText = `${item.productName} (${item.productId})`;
              return item;
            })
            .sort((a, b) => (a.productName > b.productName ? 1 : -1))
        );
      } else {
        setMixinList([]);
      }
    } catch (err) {
      console.error('PrepV2::getReconstitutedProductsCall err: ', err);
    }
  };

  const handleLoadInfusion = async () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
        setStepInitCompleted(true);
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepAssessment.patientWeights
    ) {
      try {
        // let a = ];
        const i = [];
        nursingProcess.stepAssessment.patientWeights.forEach((item) => {
          const itemWeight = {
            patientWeightLB: item.weightLB,
            calcPatientWeightKG: Math.round(item.weightLB / 2.2),
            lastKnownWeightLB: item.lastKnown,
            calcChangeFromLastKnown: item.changeFromLastKnown,
            origOrderWeightLB: Math.round(
              item.lastKnown + item.changeFromLastKnown
            ),
            dateEntered: formatDateToDefault(item.entered)
          };
          i.push(itemWeight);
        });
        setItemWeights([...i]);
      } catch (err) {
        console.error('PrepV2::nursingProcess patientWeights err: ', err);
        showError('Error: nursingProcess patientWeights');
      }
    }

    if (nursingProcess?.stepPreparation?.drugs) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepPreparation.drugs.forEach((item) => {
          const apiDrug = {
            recordNumber: item.recordNumber,
            ndc: item.ndc,
            vial: item.vial,
            strength: item.strength,
            uom: item.uom,
            quantity: item.quantity,
            route: item.route,
            lot: item.lot,
            expiration: formatDateToAWS(item.expiration),
            inventoryId: item.inventoryId
          };
          a.push(apiDrug);
          const itemDrug = {
            drugVial: `${item.vial} ${item.strength} ${item.uom}`,
            drugQty: item.quantity,
            drugLot: item.lot,
            drugExpDate: formatDateToDefault(item.expiration)
          };
          i.push(itemDrug);
        });
        setApiDrugs([...a]);
        setItemDrugs([...i]);

        // re-store dosage/wastage data here
        setDosage(setDefaultDosageState());
      } catch (err) {
        console.error('PrepV2::nursingProcess drug err: ', err);
        showError('Error: nursingProcess Drug');
      }
    }

    if (nursingProcess?.stepPreparation?.diluent) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepPreparation.diluent.forEach((item) => {
          const apiDiluent = {
            recordNumber: item.recordNumber,
            diluent: item.diluent,
            quantity: item.quantity,
            lot: item.lot,
            expiration: formatDateToAWS(item.expiration),
            inventoryId: item.inventoryId
          };
          a.push(apiDiluent);
          const itemDiluent = {
            diluentChoice: item.diluent,
            diluentQty: item.quantity,
            diluentLot: item.lot,
            diluentExpDate: formatDateToDefault(item.expiration)
          };
          i.push(itemDiluent);
        });
        setApiDiluents([...a]);
        setItemDiluents([...i]);
      } catch (err) {
        console.error('PrepV2::nursingProcess diluent err: ', err);
        showError('Error: nursingProcess Diluent');
      }
    }

    if (nursingProcess?.stepPreparation?.reconstitutedIn) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepPreparation.reconstitutedIn.forEach((item) => {
          const apiReconstituted = {
            recordNumber: item.recordNumber,
            fluid: item.fluid,
            quantity: item.quantity,
            lot: item.lot,
            expiration: formatDateToAWS(item.expiration),
            inventoryId: item.inventoryId
          };
          a.push(apiReconstituted);
          const itemReconstituted = {
            reconstitutedFluid: item.fluid,
            reconstitutedQty: item.quantity,
            reconstitutedLot: item.lot,
            reconstitutedExpDate: formatDateToDefault(item.expiration)
          };
          i.push(itemReconstituted);
        });
        setApiReconstituteds([...a]);
        setItemReconstituteds([...i]);
      } catch (err) {
        console.error('PrepV2::nursingProcess reconstituted err: ', err);
        showError('Error: nursingProcess Reconstituted');
      }
    }

    if (nursingProcess.stepPreparation?.preparationComplete) {
      setIsPreparationComplete(
        nursingProcess.stepPreparation.preparationComplete
      );
    }
  };

  const infusionForm = {
    drugVial: {
      // search both abbreviation AND name
      value: null,
      inputValidator: (value) => {
        return validateInput({ drugVial: { ...infusionForm.drugVial, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.polIdNum_REQUIRED
        }
      ]
    },
    isPreparationComplete: {
      value: !!nursingProcess.stepPreparation?.preparationComplete,
      inputValidator: (value) => {
        return validateInput({
          isPreparationComplete: {
            ...infusionForm.isPreparationComplete,
            value
          }
        });
      },
      validations: []
    },
    noMedsAdministered: {
      value: !!nursingProcess.stepPreparation?.noMedsAdministrated,
      inputValidator: (value) => {
        return validateInput({
          noMedsAdministered: { ...infusionForm.noMedsAdministered, value }
        });
      },
      validations: []
    }
  };

  const handleAddMedication = (dataItem) => {
    const wastage = calcWastage(
      dosage.selectedQuantity,
      dosage.calcDosage,
      dataItem.adjustedDosage,
      dosage.calculatedDosage
    );

    setDosage({
      ...dosage,
      wastage,
      adjustedDosage: +dataItem.adjustedDosage,
      remainingQty: calcRemaining({
        ...dosage,
        wastage,
        adjustedDosage: dataItem.adjustedDosage
      })
    });
  };

  const togglePreparationComplete = () => {
    setIsPreparationComplete(!isPreparationCompleteSwitch);
  };

  const handleAddDrug = (dataItem) => {
    const quantity = Number(dataItem?.drugQty);
    const vial = dataItem?.drugVial;
    setDrugReady(false);

    if (!quantity || !vial) return;

    const userEnteredQtyForVial = (itemDrugs || [])
      .filter((item) => item.inventoryId === vial.id)
      .reduce((total, item) => total + Number(item.drugQty), 0);

    if (quantity + userEnteredQtyForVial > vial.currentQuantity) {
      setDialogOption({
        title: 'Infusion: Prep',
        message: `Must not exceed amount that in stock. In stock ${
          vial.currentQuantity
        }, but selected ${quantity + userEnteredQtyForVial}.`,
        showDialog: true
      });
      return;
    }

    const apiDrug = {
      recordNumber: 1,
      ndc: vial.productId || '',
      vial: vial.productName || '',
      strength: vial?.strengthPerVial,
      uom: vial?.unitOfMeasure,
      quantity,
      route: vial?.route,
      lot: dataItem?.drugLot || vial?.lotNumber,
      inventoryId: vial.id,
      expiration: dataItem?.drugExpDate || vial?.expirationDate
    };

    const itemDrug = {
      drugVial: `${vial.productName} ${vial.strengthPerVial} ${vial.unitOfMeasure}`,
      drugQty: quantity,
      drugLot: vial.lotNumber,
      drugExpDate: vial.expirationDate,
      inventoryId: vial.id
    };

    const updatedApiDrugs = [...apiDrugs, apiDrug];
    const selectedQuantity = calcSelectedQuantity(updatedApiDrugs);
    const wastage = calcWastage(
      selectedQuantity,
      dosage.calcDosage,
      dosage.adjustedDosage,
      dosage.calculatedDosage
    );
    const remainingQty = calcRemaining({
      ...dosage,
      selectedQuantity,
      wastage
    });

    setDosage({
      ...dosage,
      selectedQuantity,
      wastage,
      remainingQty
    });

    try {
      setApiDrugs(updatedApiDrugs);
      setItemDrugs([...itemDrugs, itemDrug]);
    } catch (err) {
      console.error('PrepV2::handleAddDrug error:', err);
      setDialogOption({
        title: 'Infusion: Prep',
        message: 'Error: handleAddDrug',
        showDialog: true
      });
    }
  };

  const handleAddDiluent = (dataItem) => {
    const { productName, strength, unitOfMeas } = dataItem.diluentChoice;

    const diluent = formatDiluentProductName(productName, strength, unitOfMeas);

    const apiDiluent = {
      recordNumber: 1,
      diluent,
      quantity: dataItem.diluentQty,
      lot: dataItem.lotNumber,
      expiration: formatDateToAWS(dataItem.exp)
    };

    const itemDiluent = {
      diluentChoice: diluent,
      diluentQty: dataItem.diluentQty,
      diluentLot: dataItem.lotNumber,
      diluentExpDate: formatDateToDefault(dataItem.exp)
    };

    try {
      setApiDiluents([...apiDiluents, apiDiluent]);
      setItemDiluents([...itemDiluents, itemDiluent]);
    } catch (err) {
      console.error('PrepV2::handleAddDiluent err: ', err);
      setDialogOption({
        title: 'Infusion: Prep',
        message: 'Error: handleAddDiluent',
        showDialog: true
      });
    }
  };

  const handleAddReconstituted = (dataItem) => {
    const { productName, strength, unitOfMeas } = dataItem.reconstitutedFluid;
    const fluid = formatDiluentProductName(productName, strength, unitOfMeas);

    const apiReconstituted = {
      recordNumber: 1,
      fluid,
      quantity: dataItem.reconstitutedQty,
      lot: dataItem.lotNumber,
      expiration: formatDateToAWS(dataItem.exp)
    };

    const itemReconstituted = {
      reconstitutedFluid: fluid,
      reconstitutedQty: dataItem.reconstitutedQty,
      reconstitutedLot: dataItem.lotNumber,
      reconstitutedExpDate: formatDateToDefault(dataItem.exp)
    };

    try {
      setApiReconstituteds([...apiReconstituteds, apiReconstituted]);
      setItemReconstituteds([...itemReconstituteds, itemReconstituted]);
    } catch (err) {
      console.error('PrepV2::handleAddReconstituted err: ', err);
      setDialogOption({
        title: 'Infusion: Prep',
        message: 'Error: handleAddReconstituted',
        showDialog: true
      });
    }
  };

  const handleSubmit = (dataItem) => {
    const requestObject = {
      nursingProcessId,
      agentId: agent.agentId,
      drugs: apiDrugs.map((item) => {
        item.calcDosage =
          dosage.calculatedDosage > 0
            ? dosage.calculatedDosage
            : dosage.calcDosage; // AIC-3069
        item.adjustedDosage = dosage.adjustedDosage;
        item.selectedQuantity = dosage.selectedQuantity;
        item.wastage = dosage.wastage;
        // need to prevent issues on Billing/R2 step of infusion submission:
        item.route = currentReferral?.referralOrder?.administrations[0]?.route;

        // remove extra fields
        // delete item.conversionRate;

        return item;
      }),
      diluent: apiDiluents,
      reconstitutedIn: apiReconstituteds,
      preparationComplete: props.infusionCompleted
        ? true
        : !!dataItem.values?.isPreparationComplete,
      noMedsAdministrated: false // @NOTE this will be removed soon from Prer step
    };

    updateStepPrepCall(requestObject);
  };

  const handleDeleteClick = (cellProps, object) => {
    if (cellProps.dataIndex > -1) {
      if (object === 'drug') {
        if (cellProps.dataIndex > -1) {
          const cloneApiDrugs = [...apiDrugs];
          cloneApiDrugs.splice(cellProps.dataIndex, 1);
          setApiDrugs(cloneApiDrugs);
          const cloneItemDrugs = [...itemDrugs];
          cloneItemDrugs.splice(cellProps.dataIndex, 1);
          setItemDrugs(cloneItemDrugs);

          // additionally need to update dosage details
          const selectedQuantity = calcSelectedQuantity(cloneApiDrugs);
          const wastage = calcWastage(
            selectedQuantity,
            dosage.calcDosage,
            dosage.adjustedDosage,
            dosage.calculatedDosage
          );
          const remainingQty = calcRemaining({
            ...dosage,
            selectedQuantity,
            wastage
          });

          setDosage({ ...dosage, selectedQuantity, wastage, remainingQty });
        }
      }
      if (object === 'diluent') {
        if (cellProps.dataIndex > -1) {
          const cloneApiDiluents = [...apiDiluents];
          cloneApiDiluents.splice(cellProps.dataIndex, 1);
          setApiDiluents(cloneApiDiluents);
          const cloneItemDiluents = [...itemDiluents];
          cloneItemDiluents.splice(cellProps.dataIndex, 1);
          setItemDiluents(cloneItemDiluents);
        }
      }
      if (object === 'reconstituted') {
        if (cellProps.dataIndex > -1) {
          const cloneApiReconstituteds = [...apiReconstituteds];
          cloneApiReconstituteds.splice(cellProps.dataIndex, 1);
          setApiReconstituteds(cloneApiReconstituteds);
          const cloneItemReconstituteds = [...itemReconstituteds];
          cloneItemReconstituteds.splice(cellProps.dataIndex, 1);
          setItemReconstituteds(cloneItemReconstituteds);
        }
      }
    }
  };

  const checkForDrug = () => {
    if (apiDrugs.length === 0) {
      setDrugReady(true);
    } else if (apiDrugs.length !== 0) {
      setDrugReady(false);
    }
  };

  useMemo(() => {
    checkForDrug();
  }, [apiDrugs]);

  const customCellDeleteDrug = (cellProps) => {
    return (
      <td>
        <DeleteButton
          handleClick={() => handleDeleteClick(cellProps, 'drug')}
        />
      </td>
    );
  };

  const customCellDeleteDiluent = (cellProps) => {
    return (
      <td>
        <DeleteButton
          handleClick={() => handleDeleteClick(cellProps, 'diluent')}
        />
      </td>
    );
  };

  const customCellDeleteReconstituted = (cellProps) => {
    return (
      <td>
        <DeleteButton
          handleClick={() => handleDeleteClick(cellProps, 'reconstituted')}
        />
      </td>
    );
  };

  const handlePrintIVLabel = () => {
    if (showPrintIVLabel) {
      setShowPrintIVLabel(false);
    }
    if (!showPrintIVLabel) {
      setShowPrintIVLabel(true);
    }
  };

  // const printIvLabel = () => {
  //   const w = window.open();

  //   const htmlBody = `
  //     <h3>AleraCare California</h3>
  //     <hr />
  //     <p><b>${labelData.patientFirstName} ${labelData.patientLastName}</b></p>
  //     <p>DOB:&nbsp;${labelData.dob}</p>
  //     <p>IV${labelData.patientId}</p>
  //     <p>PROVIDER:&nbsp;${labelData.prescFirstName}&nbsp;${labelData.prescLastName}</p>
  //     <hr />
  //     <p><b>${labelData.productName}</b></p>
  //     <p>Meds:&nbsp;${labelData.productCode}</p>
  //     <p>Original Dosage:&nbsp;${labelData?.originalDosage}&nbsp;${labelData.uom}</p>
  //     <p>Calculated Dosage:&nbsp;${labelData.calculatedDosage}&nbsp;${labelData.uom}</p>
  //     <p>Adjusted Dosage:&nbsp;${labelData.adjustedDosage}&nbsp;${labelData.uom}</p>
  //     <p>Prepped By:</p>
  //     <p>Prepped Time:</p>
  //     <p>Use By:</p>
  //     <p><b>Federal Law Prohibits Dispensing Without a Prescription</b></p>
  //     <p>Printed:&nbsp;${labelData.dateTimeStamp}</p>
  //   `;

  //   const html = `
  //   <!DOCTYPE HTML>
  //     <html lang="en-us">
  //     <head><title>Print IV Label</title><style></style></head>
  //     <body>${htmlBody}</body>
  //   `;

  //   w.document.write(html);
  //   w.window.print();
  //   w.document.close();
  // };

  const renderNoVials = () => {
    if (isEmptyInventory) {
      const drugName =
        currentReferral?.referralOrder?.orderName || 'Unknown Drug Name';
      return (
        <small style={{ color: 'red', fontStyle: 'italic' }}>
          There&apos;s no medication for {drugName} in the inventory.
        </small>
      );
    }

    return null;
  };

  const prepareWeightInfo = () => {
    const bucketWeights =
      infusion.patientBucket?.patientProfile?.weightInfo || [];
    const npWeights = nursingProcess.stepAssessment?.patientWeights || [];

    const resWeight =
      npWeights.length > 0 ? [...npWeights] : [...bucketWeights];

    return {
      patientProfile: {
        weightInfo: [...resWeight]
      }
    };
  };

  const updatedPatientInfo = prepareWeightInfo();

  // only CLINICAL ADMINS can manage this section after 24 hrs
  if (props.infusionRestricted && !isClinicalAdmin) {
    return (
      <RevisitPrep
        handlePrintIVLabel={handlePrintIVLabel}
        showPrintIVLabel={showPrintIVLabel}
        itemDrugs={itemDrugs}
        itemWeights={itemWeights}
        itemDiluents={itemDiluents}
        itemReconstituteds={itemReconstituteds}
        infusionFormData={nursingProcess}
        labelData={labelDataNew}
        dosage={dosage}
        patientInfo={updatedPatientInfo}
      />
    );
  }

  // Medication Overage flag
  const checkOverage = () => {
    const minValue = findMinVialStrength(apiDrugs);

    if (currentReferral?.isViiv) {
      return minValue > 0 && dosage?.selectedQuantity > dosage?.calcDosage;
    }

    return dosage.wastage > 0 && dosage.wastage >= minValue;
  };
  const hasOverage = checkOverage();

  const initialFormValues = {
    isPreparationComplete: isPreparationCompleteSwitch
  };

  const isFreeDrugOrder =
    infusion?.currentReferral?.inventorySource === 'FREE_DRUG';

  return (
    <div className="infusion-page">
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {stepAllowed && (
        <>
          <div
            className="infusion-details col-md-11 mt-2 mb-3"
            style={{ border: '1px solid #afaaaa' }}
          >
            <div className="row">
              <div className="infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0">
                <div className="row">
                  <div className="col-md-2 headerText">PATIENT WEIGHT</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 mb-3">
                <PatientWeightLog patientInfo={updatedPatientInfo} />
              </div>
            </div>
          </div>

          <div
            className="infusion-details col-md-11"
            style={{ border: '1px solid #afaaaa' }}
          >
            <div className="row">
              <div className="infusion-HeaderRow col-12 ml-0 pl-3 py-3 mr-0">
                <div className="row">
                  <div className="col-md-2 headerText">
                    {' '}
                    MEDICATION: &nbsp;{' '}
                    {currentReferral?.referralOrder?.orderName}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* DRUG SELECTION */}
          <div
            className="infusion-details col-md-11 mt-2 mb-3"
            style={{
              border: '1px solid #afaaaa',
              backgroundColor: '#ffffff'
            }}
          >
            <div className="row">
              <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
                <div className="row">
                  <div className="col-md-4 headerText">DRUG SELECTION</div>
                </div>
              </div>
            </div>
            <Form
              onSubmit={handleAddDrug}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className="k-form pt-1"
                >
                  {isFreeDrugOrder && (
                    <div className="col-md-12 mt-3">
                      <Alert type="warning">
                        Current order has a <b>Free Drug</b> type as an
                        Inventory Source
                      </Alert>
                    </div>
                  )}
                  <div className="row col-md-11 mt-2 mb-3">
                    <div className="col-md-8">
                      <Field
                        component={DropDownList}
                        data={inventoryData}
                        name="drugVial"
                        label="VIAL"
                        textField="optionText"
                        validator={infusionForm.drugVial.inputValidator}
                      />
                      {renderNoVials()}
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={Input}
                        name="drugQty"
                        label="QTY"
                        validator={validateNumber}
                      />
                    </div>
                    <div className="col-md-2 mt-12">
                      <button type="submit" className="k-button blue">
                        SELECT
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
                      <Grid className="infusion-grid" data={itemDrugs}>
                        <Column field="drugQty" title="QTY" />
                        <Column field="drugVial" title="VIAL" />
                        <Column field="drugLot" title="LOT" />
                        <Column field="drugExpDate" title="EXP" />
                        <Column
                          field="action"
                          title=" "
                          cell={customCellDeleteDrug}
                        />
                      </Grid>
                    </div>
                  </div>
                </form>
              )}
            />
            {hasOverage && (
              <div className="row mb-3 pl-3">
                <div className="col-md-6">
                  <Alert>Alert: Medication Overage!</Alert>
                </div>
              </div>
            )}
            {currentReferral?.isViiv ? null : (
              <Form
                onSubmit={handleAddMedication}
                render={(formRenderProps) => (
                  <form
                    onSubmit={formRenderProps.onSubmit}
                    className="k-form pt-1"
                  >
                    <div className="infusion-details col-md-11 ml-0 pl-3 py-2 mr-0">
                      <div className="row">
                        {drugDosage.map((dsg) => {
                          if (dosage[dsg.key]) {
                            // const uom = getDosageUOM(dosage.UOM);
                            const uom = productUOM;
                            const style =
                              dsg.key === 'remainingQty'
                                ? { color: 'red' }
                                : {};
                            return (
                              <div
                                className="col-md-2 headerText"
                                style={style}
                              >
                                {/* {parseNumber(dosage[dsg.key] / rateUOM)} {uom} */}
                                {parseNumber(dosage[dsg.key])} {uom}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                      <div className="row">
                        {drugDosage.map((currDosage) => {
                          if (dosage[currDosage.key]) {
                            return (
                              <div className="col-2 infusion-details-field-name">
                                {currDosage.title}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-3">
                          <Field
                            component={Input}
                            name="adjustedDosage"
                            label="Adjusted Dosage"
                          />
                        </div>
                        <div className="col-md-3 mt-12">
                          <button type="submit" className="k-button blue">
                            Adjust Dosage
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              />
            )}
          </div>
          {/* DILUENT SELECTION */}
          <Form
            validator={diluentValidators}
            onSubmit={handleAddDiluent}
            render={(formRenderProps) => (
              <form onSubmit={formRenderProps.onSubmit} className="k-form pt-1">
                <div
                  className="infusion-details col-md-11 mt-2 mb-3"
                  style={{
                    border: '1px solid #afaaaa',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className="row">
                    <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
                      <div className="row">
                        <div className="col-md-4 headerText">
                          DILUENT SELECTION
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-11 mt-3 mb-3">
                    <div className="col-md-4">
                      <Field
                        component={DropDownList}
                        data={diluentList}
                        name="diluentChoice"
                        label="DILUENT"
                        textField="optionText"
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="diluentQty"
                        label="QTY"
                        validator={validateNumber}
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="lotNumber"
                        label="LOT NUMBER"
                      />
                    </div>
                    <div className="col-md-2 ">
                      Expiration Date
                      <br />
                      <Field component={DatePickerField} name="exp" />
                    </div>
                    <div className="col-md-2 mt-12">
                      <button type="submit" className="k-button blue">
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
                      <Grid className="infusion-grid" data={itemDiluents}>
                        <Column field="diluentQty" title="QTY" />
                        <Column field="diluentChoice" title="DILUENT" />
                        <Column field="diluentLot" title="LOT" />
                        <Column field="diluentExpDate" title="EXP" />
                        <Column
                          field="action"
                          title=" "
                          cell={customCellDeleteDiluent}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          {/* RECONSTITUTED-IN SELECTION */}
          <Form
            validator={diluentValidators}
            onSubmit={handleAddReconstituted}
            render={(formRenderProps) => (
              <form onSubmit={formRenderProps.onSubmit} className="k-form pt-1">
                <div
                  className="infusion-details col-md-11 mt-2 mb-3"
                  style={{
                    border: '1px solid #afaaaa',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className="row">
                    <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
                      <div className="row">
                        <div className="col-md-4 headerText">
                          RECONSTITUTED FLUID SELECTION
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-11 mt-3 mb-3">
                    <div className="col-md-4">
                      <Field
                        component={DropDownList}
                        // data={mixins}
                        data={mixinList}
                        name="reconstitutedFluid"
                        label="RECONSTITUTED FLUID"
                        textField="optionText"
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="reconstitutedQty"
                        label="QTY"
                        validator={validateNumber}
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="lotNumber"
                        label="LOT NUMBER"
                      />
                    </div>
                    <div className="col-md-2 ">
                      Expiration Date
                      <br />
                      <Field component={DatePickerField} name="exp" />
                    </div>
                    <div className="col-md-2 mt-12">
                      <button type="submit" className="k-button blue">
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
                      <Grid className="infusion-grid" data={itemReconstituteds}>
                        <Column field="reconstitutedQty" title="QTY" />
                        <Column field="reconstitutedFluid" title="DILUENT" />
                        <Column field="reconstitutedLot" title="LOT" />
                        <Column field="reconstitutedExpDate" title="EXP" />
                        <Column
                          field="action"
                          title=" "
                          cell={customCellDeleteReconstituted}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          {/* SUBMIT FORM */}
          <Form
            onSubmitClick={handleSubmit}
            initialValues={initialFormValues}
            key={JSON.stringify(initialFormValues)}
            render={({ onSubmit }) => (
              <form onSubmit={onSubmit} className="k-form pl-3 pr-3 pt-1">
                <div className="row col-md-12 mt-3 mb-3">
                  <div className="col-md-3">
                    Preparation Complete: &nbsp;
                    <Field
                      name="isPreparationComplete"
                      onLabel="Yes"
                      offLabel="No"
                      component={Switch}
                      value={isPreparationCompleteSwitch}
                      onChange={togglePreparationComplete}
                      defaultChecked={isPreparationCompleteSwitch}
                      disabled={props.infusionCompleted}
                    />
                  </div>
                </div>
                {drugReady && (
                  <div className="row mt-1 ml-3">
                    <Alert>ALERT: No medications have been selected</Alert>
                  </div>
                )}
                <div className="row mt-5 mb-5">
                  <div className="col-md-3">
                    <button type="submit" className="k-button pageButton">
                      Save
                    </button>
                  </div>
                  <div className="col-md-9">
                    <Button
                      type="button"
                      className="k-button"
                      onClick={() => printIvLabel(labelDataNew)}
                      disabled={!isPreparationCompleteSwitch}
                    >
                      <i className="fa-solid fa-print" />
                      &nbsp;Print IV Label
                    </Button>
                    <div style={{ marginLeft: 25, display: 'inline-block' }}>
                      &nbsp;
                    </div>
                    <Button
                      type="button"
                      className="k-button"
                      onClick={handlePrintIVLabel}
                      disabled={!isPreparationCompleteSwitch}
                    >
                      <i className="fa-solid fa-file-pdf" />
                      &nbsp;Download IV Label
                    </Button>
                  </div>
                  <div className="col-md-3" />
                </div>
              </form>
            )}
          />
        </>
      )}
      <WindowDialog
        title="Print IV Label"
        style={{ backgroundColor: '#FFFFFF', minHeight: '300px' }}
        initialHeight={800}
        initialTop={1}
        width={600}
        showDialog={showPrintIVLabel}
        onClose={handlePrintIVLabel}
      >
        <IVLabel labelData={labelDataNew} />
      </WindowDialog>
    </div>
  );
};

export default PrepV2;
