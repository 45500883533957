import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Form } from '@progress/kendo-react-form';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { orderBy } from '@progress/kendo-data-query';

import { LocationContext } from '@/context/LocationContext';
/* import { PatientBillingSearch } from "./PatientBillingSearch"; */

const initialSort = [
  {
    field: 'serviceDate',
    dir: 'asc'
  }
];

export const PatientBillingList = ({ selectAction, patientBillings }) => {
  const [sort, setSort] = useState(initialSort);
  const { getDateByLocation } = useContext(LocationContext);

  const serviceDateCell = ({ dataItem }) => {
    const dos = getDateByLocation(
      dataItem.locationId,
      dataItem.serviceDateTime
    );

    return <td>{dos}</td>;
  };

  return (
    <Form
      render={(formRenderProps) => (
        <form
          onSubmit={formRenderProps.onSubmit}
          className={'k-form pl-3 pr-3 pt-1'}
        >
          {/* <PatientBillingSearch /> */}
          <div className="row">
            <div className="col-md-12 ml-1 mr-1 mt-3">
              <div>
                <div className="a-grid__header">
                  <div>BILLING HISTORY</div>
                </div>
                <Grid
                  className="infusion-grid"
                  selectedField="selected"
                  sort={sort}
                  data={orderBy(patientBillings, sort)}
                  sortable
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                >
                  <Column
                    field="serviceDate"
                    title="DATE OF SERVICE"
                    width="150px"
                    cell={serviceDateCell}
                  />
                  <Column field="locationName" title="LOCATION" width="150px" />
                  <Column field="orderName" title="ORDER NAME" width="200px" />
                  <Column field="r2Status" title="OPTML STATUS" width="150px" />
                  <Column
                    field="billingStatus"
                    title="R2 STATUS"
                    width="150px"
                  />
                  <Column
                    field="id"
                    title=" "
                    width="150px"
                    cell={selectAction}
                  />
                </Grid>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

PatientBillingList.propTypes = {
  selectAction: PropTypes.func,
  patientBillings: PropTypes.array
};
